import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as bgtext from "brandguard/display/brandguard/text"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import * as authz from "authz"
import classnames from "classnames"
import * as scores from "brandguard/components/layouts/scores"
import * as debugx from "x/debugx"
import * as icons from "icons"
import * as bgscores from "brandguard/display/scores"
import * as typography from "typography"
import * as mediaapi from "media/api"
import * as timex from "timex"

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const mtoggle = layouts.modals.useToggle()

	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.TextSearchResponseItem | undefined)
	const [checkedItems, setCheckedItems] = useState([] as api.TextSearchResponseItem[])
	const [filterFocused, setFilterFocused] = useState(false)

	const [undoItems, setUndoItems] = useState<api.TextSearchResponseItem[]>([])

	const _searchReq = api.text.uploads.searchZero({
		offset: 0n,
		brand_id: brand.id,
		queues: [brand.id],
	})

	const [searchReq, setSearchReq] = useState(_searchReq)
	const [cursorKey, setCursorKey] = useState(uuid.v4())

	const [texts, setTexts] = useState({
		items: [],
		next: searchReq,
	} as api.TextSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.reviewable.search(searchReq.brand_id, searchReq, bearertoken))
			.then(setTexts)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => console.error("unable to search for brandguard reviewable approval text", cause))
			.finally(() => {
				setLoading(false)
			})
		return p.cancel
	}, [searchReq])

	useEffect(() => {
		if (!selectedItem) return
		mtoggle(
			<layouts.Flex styled flexDirection="column">
				<bgapprovals.TextPreview
					item={selectedItem}
					flex="1"
					onClose={() => {
						mtoggle(undefined)
						setSelectedItem(undefined)
					}}
				>
					<typography.h3 fontWeight="700" fontSize="18px" lineHeight="30px" color="#4D5E80">
						Asset Scores
					</typography.h3>
					<bgtext.Scores item={selectedItem} />
					<bgapprovals.status.default>
						<bgapprovals.status.Review
							item={selectedItem}
							previtemstate={undoItems.find((u) => u.media?.id === selectedItem.media?.id)}
							agree={approve}
							disagree={reject}
							undo={undo}
							borderRadius="10px"
						/>
					</bgapprovals.status.default>
					<authz.Protected enabled={debugx.alpha.enabled()}>
						<bgscores.layouts.accordion.Container title="Debug Info" textAlign="initial">
							<bgtext.Debug item={selectedItem} />
						</bgscores.layouts.accordion.Container>
					</authz.Protected>
					<layouts.containers.flex className="actions" flex="1" alignItems="flex-end">
						<authz.Protected enabled={permission.brandguard_upload}>
							<bgtext.MultiActions uploadItems={[selectedItem]} />
						</authz.Protected>
					</layouts.containers.flex>
				</bgapprovals.TextPreview>
			</layouts.Flex>,
		).then((e) => setSelectedItem(undefined))
	}, [selectedItem])

	const onTextUpdate = (item: api.TextSearchResponseItem): void => {
		const remove = (items: api.TextSearchResponseItem[]) => items.filter((i) => i.media?.id !== item.media?.id)
		const replace = (items: api.TextSearchResponseItem[]) =>
			items.map((i) => (i.media?.id === item.media?.id ? item : i))
		const removed = timex.from.iso(item.media?.tombstoned_at || timex.infinity().toISO()) < timex.local()
		const op = removed ? remove : replace

		setTexts((prevState) => ({ ...prevState, items: op(prevState.items) }))
	}

	const approve = (
		item: api.TextSearchResponseItem & { event: api.TextEvent },
	): Promise<api.TextSearchResponseItem> => {
		return api.text.reviewable
			.publish(
				item.event.id,
				item.event.brand_id,
				{
					...item,
					event: {
						...item.event,
						observation: api.Prediction.APPROVED,
					},
				},
				authzc.bearer(authzaccount),
			)
			.then((res_i) => {
				setUndoItems([...undoItems, ...[item]])
				setTexts((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
				}))
				if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
				return res_i
			})
	}

	const reject = (item: api.TextSearchResponseItem & { event: api.TextEvent }): Promise<api.TextSearchResponseItem> => {
		return api.text.reviewable
			.publish(
				item.event.id,
				item.event.brand_id,
				{
					...item,
					event: {
						...item.event,
						observation: api.Prediction.REJECTED,
					},
				},
				authzc.bearer(authzaccount),
			)
			.then((res_i) => {
				setUndoItems([...undoItems, ...[item]])
				setTexts((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
				}))
				if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
				return res_i
			})
	}

	const undo = (
		current: api.TextSearchResponseItem & { event: api.TextEvent },
		previously: api.TextSearchResponseItem & { event: api.TextEvent },
	): Promise<api.TextPatchResponse> => {
		return api.text.uploads
			.patch(previously.event.id, previously.event.brand_id, previously, authzc.bearer(authzaccount))
			.then((resp_i) => {
				setUndoItems(undoItems.filter((i) => i.media?.id !== previously.media?.id))
				setTexts((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === previously.media?.id ? previously : i)),
				}))
				if (selectedItem && selectedItem.media?.id === previously.media?.id) setSelectedItem(previously)
				return resp_i
			})
			.then((upload) => {
				return api.text.approvallog.find(current.event.id, current.event.brand_id, bearertoken).then((result) => {
					return api.text.approvallog
						.destroy(result.event!.brand_id, result.event!.id, authzc.bearer(authzaccount))
						.then(() => {
							return upload
						})
				})
			})
	}

	const _allselected = texts.items.length > 0 && checkedItems.length === texts.items.length

	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedItems([])
			return
		}
		setCheckedItems(texts.items)
	}

	const appchecked = searchReq.approved.length === 1 && searchReq.approved[0] === api.Prediction.APPROVED
	const rejchecked = searchReq.approved.length === 1 && searchReq.approved[0] === api.Prediction.REJECTED
	const fcnt = bgapprovals.CalculateFilterSCount(_searchReq, searchReq)

	return (
		<layouts.containers.flex flexDirection="column-reverse" overflow="hidden">
			<layouts.pagination.Cursor
				key={cursorKey}
				my="auto"
				justifyContent="center"
				current={searchReq.offset}
				advance={Number(texts.next!.offset) === -1 ? undefined : texts.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
			<layouts.loading.screen
				className="brandguard-working-area"
				loading={loading}
				flex="1"
				overflow="hidden"
				icon={<></>}
				flexDirection="row"
			>
				<layouts.containers.flex className="center-panel" flex="6" flexDirection="column">
					<layouts.containers.flex flexDirection="column" flex="1" minHeight="100px">
						<bglayouts.OverlaysContainer overflow="auto" background={layouts.theme.backgrounds.whitealpha80}>
							<layouts.containers.ResponsiveGrid gap={20} p="25px">
								{texts.items.map((item) => {
									return (
										<bgapprovals.texts.CardDisplay
											key={item.media?.id}
											item={item}
											active={selectedItem && item?.media?.id === selectedItem.media?.id}
											onCardClick={() => {
												setSelectedItem(item)
											}}
											onChange={onTextUpdate}
											onChecked={(checked) =>
												setCheckedItems((prevState) =>
													checked ? [...prevState, item] : prevState.filter((i) => i.media?.id !== item.media?.id),
												)
											}
											score={
												<layouts.containers.absolute>
													<scores.BGScoreTextSimple event={item.event!} />
												</layouts.containers.absolute>
											}
											panel={
												<bgapprovals.status.default>
													<bgapprovals.status.Review
														item={item}
														previtemstate={undoItems.find((u) => u.media?.id === item.media?.id)}
														agree={approve}
														disagree={reject}
														undo={undo}
														borderRadius="0 0 10px 10px"
													/>
												</bgapprovals.status.default>
											}
											find={api.text.uploads.find}
											checked={checkedItems.some((i) => i.media?.id === item.media?.id)}
										/>
									)
								})}
							</layouts.containers.ResponsiveGrid>
							<layouts.loading.pending loading={loading || texts.next?.offset === searchReq.offset}>
								{texts.items.length === 0 && <bgapprovals.layouts.ZeroState />}
							</layouts.loading.pending>
						</bglayouts.OverlaysContainer>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.screen>
			<layouts.containers.flex flexDirection="row" className={classnames(bgapprovals.layouts.styledactions)}>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
					<bgapprovals.layouts.FilterButton
						width="145px"
						height="40px"
						borderRadius="4px"
						onClick={() => setFilterFocused(!filterFocused)}
						className={fcnt > 0 ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">Filter{fcnt > 0 ? `/${fcnt}` : ""}</layouts.containers.box>
							<layouts.containers.box>
								{filterFocused ? <icons.bgapproval.arrowDown /> : <icons.bgapproval.arrowDown />}
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgapprovals.layouts.FilterButton>
					<layouts.containers.flex display={filterFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<bgapprovals.FilterDisplay
							onFilterClear={() => {
								setSearchReq(_searchReq)
								setCursorKey(uuid.v4())
							}}
							_searchReq={_searchReq}
							onFilterChange={(r) => {
								setSearchReq({ ...searchReq, ...r, ...{ offset: 0n } })
								setCursorKey(uuid.v4())
							}}
						>
							<bgapprovals.Checkbox
								description="Assets that were approved"
								checked={appchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										approved: appchecked ? _searchReq.approved : [api.Prediction.APPROVED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
							<bgapprovals.Checkbox
								description="Assets that were rejected"
								checked={rejchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										approved: rejchecked ? _searchReq.approved : [api.Prediction.REJECTED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
						</bgapprovals.FilterDisplay>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex className="actions" justifyContent="flex-end" flex="10">
					<bgapprovals.layouts.SelectAll
						disabled={texts.items.length === 0}
						cursor="pointer"
						className="select-all-action"
						onClick={handleSelectAll}
					>
						{_allselected ? "Unselect All" : "Select All"}
					</bgapprovals.layouts.SelectAll>
					<authz.Protected
						enabled={permission.brandguard_upload && checkedItems.length > 0}
						rejected={
							<layouts.containers.flex className="download-action">
								<icons.brandguard.download />
							</layouts.containers.flex>
						}
					>
						<layouts.containers.flex
							className="download-action"
							cursor="pointer"
							onClick={() => {
								const promises: Promise<api.TextPatchRequest | string>[] = []
								checkedItems.forEach((i) => {
									promises.push(
										mediaapi.url(i.media?.md5!, bearertoken).then((result) => {
											return fetch(result.url).then((resp) => resp.text())
										}),
									)
								})
								Promise.all(promises).then((content) => {
									const downloadLink = document.createElement("a")
									downloadLink.href = URL.createObjectURL(new Blob([content.join("\n")], { type: "text/csv" }))
									downloadLink.download = "brandguard-assets.csv"
									downloadLink.click()
									downloadLink.remove()
								})
							}}
						>
							<icons.brandguard.download />
						</layouts.containers.flex>
					</authz.Protected>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
