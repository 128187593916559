import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as icons from "icons"
import * as api from "./api"
import * as caching from "./cache"
import * as edit from "./edit"
import { SearchResponse } from "./account.brand"
import * as typography from "typography"
import * as inputs from "inputs"
import * as routing from "./routing"
import { CancellablePromise } from "real-cancellable-promise"
import { css } from "@emotion/css"
import * as debugx from "x/debugx"
import * as brandguard from "brandguard"
import * as metrics from "metrics"
import * as training from "brandguard/display/training"
import * as authz from "authz"

const cardHover = css`
	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
	}
`

const iconHover = css`
	&:hover {
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

const textWithOverflow = css`
	overflow: hidden;
	text-overflow: ellipsis;
`

interface props {
	nobrands?: JSX.Element
	search(req: api.SearchRequest, ...options: httpx.option[]): CancellablePromise<api.SearchResponse>
}

export default function Grid(props: props): JSX.Element {
	const { search, nobrands } = props
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const [breq, setBrandRequest] = useState(api.searches.zero({ limit: 25 }))
	const [brands, setBrands] = useState({
		next: breq,
		items: [],
	} as SearchResponse)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => search(breq, bearertoken))
			.then((brands) => {
				setBrands(brands)
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve brands</errors.Textual>
					</errors.Inline>,
				)
				setLoading(false)
			})
		return pending.cancel
	}, [breq])

	return (
		<layouts.overlays.Container flex="1">
			<layouts.Flex className="brands-display" px="50px" pt="33px" flex="1" flexDirection="column">
				<layouts.Flex flexDirection="row" alignItems="center" justifyContent="center" mb="25px">
					<layouts.Flex flex="1" justifyContent="left">
						<typography.h3 color={layouts.theme.colors.grey.medium}>Brands</typography.h3>
					</layouts.Flex>
					<layouts.Flex flex="1" justifyContent="center">
						<inputs.Text
							padding="0px 45px"
							borderRadius="30px"
							height="48px"
							icon={
								<inputs.icons.left>
									<icons.searches.Magnifier />
								</inputs.icons.left>
							}
							placeholder="Search"
							defaultValue={breq.query}
							onChange={(evt) => {
								setBrandRequest({ ...breq, query: evt.currentTarget.value })
							}}
						/>
					</layouts.Flex>
					<layouts.Flex flex="1" />
				</layouts.Flex>
				{debugx.alpha.enabled() && (
					<layouts.overlays.Inline enabled={permission.usermanagement}>
						<layouts.Flex
							mb="25px"
							borderRadius="5px"
							onClick={(evt) => {
								mtoggle(
									<edit.New
										borderRadius="5px"
										width="unset"
										px="50px"
										py="30px"
										overflow="auto"
										onChange={(brand) => {
											const retry = httpx.autoretry()
											retry
												.wrap(() => api.create({ brand: brand }, authzc.bearer(metaauthz)))
												.then((r) => {
													setBrands({
														...brands,
														items: [r.brand!].concat(...brands.items),
													})
													api.setLastBrandId(r.brand!.account_id, r.brand!.id)
													mtoggle(undefined)
													// mtoggle(<edit.AdConnect />) TODO reenable.
												})
										}}
									/>,
								)
							}}
						>
							<icons.New />
						</layouts.Flex>
					</layouts.overlays.Inline>
				)}

				{cause}
				<layouts.loading.screen
					className="grid-area"
					loading={loading}
					flex="1"
					overflow="hidden"
					icon={<></>}
					flexDirection="row"
				>
					<layouts.containers.flex className="center-panel" flex="1" flexDirection="column" minHeight="100px">
						<layouts.overlays.Container
							height="100vh"
							borderRadius="10px"
							flexDirection="column"
							textAlign="center"
							background={layouts.theme.backgrounds.whitealpha80}
							overflow="auto"
						>
							<layouts.loading.pending loading={loading}>
								<layouts.containers.grid
									display="grid"
									gridGap="50px"
									gridTemplateColumns="repeat(auto-fill, 280px)"
									px="40px"
									py="50px"
								>
									{brands.items.map((b, index) => (
										<layouts.Flex
											key={b.id}
											className={cardHover}
											px="25px"
											pt="20px"
											width="226px"
											height="100px"
											background={layouts.theme.colors.white}
											borderRadius="10px"
											border={`0.5px solid ${layouts.theme.colors.grey.light50}`}
											boxShadow={layouts.theme.boxshadow.black.medium}
											flexDirection="column"
										>
											<layouts.Flex flexDirection="column">
												<Link
													to={
														permission.brandguide_view || permission.brandguide_edit
															? routing.brandguide(b.id)
															: routing.brandguard(b.id)
													}
													style={{ textDecoration: "none" }}
												>
													<typography.h2
														color={layouts.theme.colors.grey.dark50}
														textAlign="left"
														title={b.description}
														fontSize="16px"
														fontWeight="500"
														className={textWithOverflow}
													>
														{b.description}
													</typography.h2>
													<typography.h4
														color={layouts.theme.colors.blue.blue}
														textAlign="left"
														title={b.domain}
														fontSize="12px"
														fontWeight="400"
														className={textWithOverflow}
													>
														{b.domain}
													</typography.h4>
												</Link>
											</layouts.Flex>
											<layouts.Flex mt="5px" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`} />
											<layouts.Flex justifyContent="space-evenly" pt="5px" gridGap="10px">
												{permission.brandguard_train && (
													<Link to={training.routing.image.upload(b.id)}>
														<layouts.Flex className={iconHover} p="5px" title="Asset Training">
															<icons.AssetsTraining width="14px" height="14px" />
														</layouts.Flex>
													</Link>
												)}
												<Link to={brandguard.routing.image.score(b.id)}>
													<layouts.Flex className={iconHover} p="5px" title="BrandGuard">
														<icons.Brandguard width="14px" height="14px" />
													</layouts.Flex>
												</Link>
												<authz.Protected enabled={permission.brandgpt_use}>
													<Link to={routing.brandgpt(b.id)}>
														<layouts.Flex className={iconHover} p="5px" title="BrandGPT">
															<icons.BrandGPT width="14px" height="14px" />
														</layouts.Flex>
													</Link>
												</authz.Protected>
												{debugx.alpha.enabled() && (
													<Link to={metrics.routing.metrics_approvals(b.id)}>
														<layouts.Flex className={iconHover} p="5px" title="Metrics">
															<icons.Metrics width="14px" height="14px" fill={layouts.theme.colors.grey.dark50} />
														</layouts.Flex>
													</Link>
												)}
												<layouts.overlays.Inline enabled={permission.usermanagement}>
													<layouts.containers.flex
														className={iconHover}
														p="5px"
														title="Edit Brand"
														onClick={() => {
															mtoggle(
																<edit.Edit
																	borderRadius="5px"
																	width="unset"
																	px="50px"
																	py="30px"
																	overflow="auto"
																	current={b}
																	onDelete={(b) => {
																		const retry = httpx.autoretry()
																		retry
																			.wrap(() => api.destroy(b.id, authzc.bearer(metaauthz)))
																			.then((r) => {
																				setBrands({
																					...brands,
																					items: brands.items.filter((c) => c.id !== r.brand!.id),
																				})
																				api.getLastBrandId(r.brand!.account_id).then((bid) => {
																					if (bid !== r.brand!.id) return
																					api.removeLastBrandId(r.brand!.account_id)
																					caching.cache.flush()
																				})
																			})
																		mtoggle(undefined)
																	}}
																	onChange={(upd) => {
																		const retry = httpx.autoretry()
																		retry
																			.wrap(() => api.update({ brand: upd }, authzc.bearer(metaauthz)))
																			.then((r) => {
																				setBrands({
																					...brands,
																					items: brands.items.map((el) => (el.id === r.brand?.id ? r.brand : el)),
																				})
																				caching.cache.set(r.brand!.id, r.brand)
																			})
																			.catch((e: unknown) => console.error(e))
																		mtoggle(undefined)
																	}}
																></edit.Edit>,
															)
														}}
													>
														<icons.Pencil width="17px" height="17px" />
													</layouts.containers.flex>
												</layouts.overlays.Inline>
											</layouts.Flex>
										</layouts.Flex>
									))}
									<layouts.Flex
										className={cardHover}
										px="25px"
										pt="20px"
										width="226px"
										height="100px"
										background={layouts.theme.colors.white}
										borderRadius="10px"
										border={`0.5px solid ${layouts.theme.colors.grey.light50}`}
										boxShadow={layouts.theme.boxshadow.black.medium}
										flexDirection="column"
									>
										<layouts.Flex flexDirection="column">
											<typography.h2
												color={layouts.theme.colors.grey.dark50}
												textAlign="left"
												fontSize="16px"
												fontWeight="500"
												className={textWithOverflow}
											>
												Need more brands?
											</typography.h2>
											<typography.h2
												color={layouts.theme.colors.grey.dark50}
												textAlign="left"
												fontSize="16px"
												fontWeight="500"
												className={textWithOverflow}
											>
												Contact us at
											</typography.h2>
										</layouts.Flex>
										<layouts.Flex justifyContent="space-between" mt="5px">
											<layouts.links.external target="_black" href={"https://www.brandguard.ai/contact"}>
												<typography.h4
													color={layouts.theme.colors.blue.blue}
													textAlign="left"
													fontSize="12px"
													fontWeight="400"
													className={textWithOverflow}
												>
													https://www.brandguard.ai/contact
												</typography.h4>
											</layouts.links.external>
										</layouts.Flex>
									</layouts.Flex>
								</layouts.containers.grid>
								<layouts.overlays.Screen
									enabled={!!nobrands && brands.items.length === 0 && breq.query === ""}
									display="flex"
								>
									{nobrands}
								</layouts.overlays.Screen>
							</layouts.loading.pending>
						</layouts.overlays.Container>
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.pagination.Cursor
					justifyContent="center"
					current={breq.offset}
					advance={Number(brands.next!.offset) === -1 ? undefined : brands.next?.offset}
					onChange={(next) => {
						setBrandRequest({
							...breq,
							offset: next,
						})
					}}
				/>
			</layouts.Flex>
		</layouts.overlays.Container>
	)
}

Grid.defaultProps = {
	search: api.search,
}
