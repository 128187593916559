import React, { useState, useEffect, useRef } from "react"
import * as layouts from "layouts"
import * as brandguide from "brandguide"
import * as authz from "authz"
import * as icons from "icons"
import { useGeneratePdf, download } from "brandguide/build/pdf"
import { useDataToKernelsImg, useDataToKernelsText } from "brandguide/build/kernels"
import { useDataToTrainingImg, useDataToTrainingText } from "brandguide/build/training"
import * as api from "brandguard/api"
import * as httpx from "httpx"
import * as uuid from "uuid"
import * as authzc from "authzcached"
import * as brandguide_api from "brandguide/api"

const buttonBaseProps = {
	borderRadius: "5px",
	height: "34px",
	width: "80px",
	className: "no-print",
	padding: "unset",
}

export default function Display(): JSX.Element {
	const printRef = useRef<HTMLDivElement>(null)
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const { loading, generatePdf } = useGeneratePdf(printRef, guide.version)

	const selected = brandguide.validations.guide.sections_enabled(guide).filter((p) => p)
	const completed = brandguide.validations.guide.pending_pages(guide).filter((p) => p)
	const allowSave = completed.length === selected.length

	const _loadedSections = (guide.guide?.imagery?.enabled ? 1 : 0) + (guide.guide?.application?.enabled ? 1 : 0)
	const [loadedSections, setLoadedSections] = useState(_loadedSections)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const [imgKernel, setImgKernel] = useState(api.images.kernel.config.zero())
	const [textKernel, setTextKernel] = useState(api.text.kernel.config.zero())

	const { passDataToImgKernels } = useDataToKernelsImg(imgKernel)
	const { passDataToTextKernels } = useDataToKernelsText(textKernel)

	const onBrandImgs = [
		...(guide.guide?.imagery?.product?.items || []),
		...(guide.guide?.imagery?.lifestyle?.items || []),
	]
	const offBrandImgs = guide.guide?.imagery?.misuse || []
	const { passImgDataToTraining } = useDataToTrainingImg(onBrandImgs, offBrandImgs)

	const onBrandText = (guide.guide?.tone_of_voice?.writing_style || []).map((t) => t.text)
	const { passTextDataToTraining } = useDataToTrainingText(onBrandText)

	useEffect(() => {
		if (!allowSave) return
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.images.kernel.config.latest(guide.brand_id, guide.brand_id, authzc.bearer(authzaccount)))
			.then((r) => {
				setImgKernel(api.images.kernel.config.zero({ ...r.settings!, id: uuid.v4() }))
			})
			.catch(httpx.errors.notFound((cause) => console.debug("unable to find image kernel", cause)))
			.catch((cause) => console.warn(cause))
		return p.cancel
	}, [])

	useEffect(() => {
		if (!allowSave) return
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.kernel.config.latest(guide.brand_id, guide.brand_id, authzc.bearer(authzaccount)))
			.then((r) => {
				setTextKernel(api.text.kernel.config.zero({ ...r.settings!, id: uuid.v4() }))
			})
			.catch(httpx.errors.notFound((cause) => console.debug("unable to find text kernel", cause)))
			.catch((cause) => console.warn(cause))
		return p.cancel
	}, [])

	const GeneratePDFButton = () => {
		if (guide.pdf_generated)
			return (
				<layouts.buttons.primary
					title="Download"
					onClick={() => {
						if (loading) return
						const retry = httpx.autoretry()
						retry
							.wrap(() => brandguide_api.get_pdf(guide.brand_id, guide.version, authzc.bearer(authzaccount)))
							.then((r) => {
								return r.blob()
							})
							.then((b) => {
								download(b)
							})
					}}
					{...buttonBaseProps}
				>
					{loading ? (
						<icons.loading.Ring3 width="20px" height="20px" stroke={layouts.theme.colors.white} />
					) : (
						<icons.pdf.download width="30px" height="30px" stroke={layouts.theme.colors.white} />
					)}
				</layouts.buttons.primary>
			)
		return (
			<layouts.buttons.primary
				onClick={() => {
					passDataToImgKernels()
					passDataToTextKernels()
					passImgDataToTraining()
					passTextDataToTraining()
					cache.store({ ...cache, guide: { ...cache.guide, pdf_generated: true } }, true)
					generatePdf()
				}}
				disabled={loadedSections !== 0 || !allowSave}
				{...buttonBaseProps}
			>
				Save
			</layouts.buttons.primary>
		)
	}

	return (
		<>
			<authz.Protected enabled={permission.brandguide_edit}>
				<GeneratePDFButton />
			</authz.Protected>
			<authz.Protected enabled={allowSave}>
				<layouts.Flex display="none">
					<layouts.Flex ref={printRef} flexDirection="column" className="main-print">
						<authz.Protected enabled={!!guide.guide?.colors?.enabled}>
							<brandguide.build.colors.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.logos?.enabled}>
							<brandguide.build.logos.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.typography?.enabled}>
							<brandguide.build.typography.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.imagery?.enabled}>
							<brandguide.build.imagery.SectionResult
								onChange={() => {}}
								onLoad={() => setLoadedSections((prev) => prev - 1)}
								className="section-print"
							/>
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.tone_of_voice?.enabled}>
							<brandguide.build.tov.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.archetype?.enabled}>
							<brandguide.build.archetype.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.compliance?.enabled}>
							<brandguide.build.compliance.SectionResult onChange={() => {}} className="section-print" />
						</authz.Protected>
						<authz.Protected enabled={!!guide.guide?.application?.enabled}>
							<brandguide.build.application.SectionResult
								onChange={() => {}}
								onLoad={() => setLoadedSections((prev) => prev - 1)}
								className="section-print"
							/>
						</authz.Protected>
					</layouts.Flex>
				</layouts.Flex>
			</authz.Protected>
		</>
	)
}
