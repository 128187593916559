import { Route, Routes, Navigate } from "react-router-dom"
import * as brands from "brands"
import * as layouts from "layouts"
import * as routing from "./routing"
import * as bgtext from "brandguard/display/brandguard/text"
import * as bgimages from "brandguard/display/brandguard/images"

export default function Routing(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<Routes>
				<Route path="/image/score" element={<bgimages.Scoring />} />
				<Route path="/text/score" element={<bgtext.Scoring />} />
				<Route path="/approval/image" element={<bgimages.approval.upload />} />
				<Route path="/approval/image/review" element={<bgimages.approval.review />} />
				<Route path="/approval/image/approved" element={<bgimages.approval.approved />} />
				<Route path="/approval/image/rejected" element={<bgimages.approval.rejected />} />
				<Route path="/approval/text" element={<bgtext.approval.upload />} />
				<Route path="/approval/text/review" element={<bgtext.approval.review />} />
				<Route path="/approval/text/approved" element={<bgtext.approval.approved />} />
				<Route path="/approval/text/rejected" element={<bgtext.approval.rejected />} />
				<Route path="/approval" element={<Navigate to={routing.image.approval.upload(brand.id)} replace />} />
				<Route path="*" element={<Navigate to={routing.image.score(brand.id)} replace />} />
			</Routes>
		</layouts.backgrounds.Grey>
	)
}
