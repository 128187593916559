import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as icons from "icons"
import * as sessions from "sessions"
import Display from "./login.display"

export function Login(props: Partial<sessions.api.LoginOptions>): JSX.Element {
	const theme = layouts.useTheme()
	const [loading, setLoading] = useState(true)
	const [loginopts, setLoginOpts] = useState(sessions.api.loginopts.zero({ ...props }))

	useEffect(() => {
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => sessions.api.loginopts.get(loginopts.redirect, loginopts))
			.then((r) => {
				setLoginOpts(r)
				setLoading(false)
			})
			.catch((cause) => {
				console.error("unable to retrieve loginopts", cause)
				setLoading(false)
			})
		return pending.cancel
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<layouts.backgrounds.Grey flex="0 1 100%">
			<layouts.containers.flex
				width="100vw"
				justifyContent="center"
				alignItems="center"
				background={theme.color(layouts.theme.colors.white, layouts.theme.colors.blue.dark20)}
			>
				<layouts.containers.flex justifyContent="space-between" flex="1" flexWrap="wrap">
					<layouts.loading.pending loading={loading}>
						<Display {...loginopts} />
						<layouts.containers.flex flex="1" background={layouts.theme.colors.blue.dark10} minWidth="400px">
							<icons.LoginIcons width="100%" />
						</layouts.containers.flex>
					</layouts.loading.pending>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}
