import React, { useEffect, useState } from "react"
import { CancellablePromise } from "real-cancellable-promise"
import * as luxon from "luxon"
import { Route, Routes, useLocation } from "react-router-dom"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as profiles from "profiles/profile"
import * as typography from "typography"
import * as api from "./api"
import * as sessions from "./session"
import * as authn from "./authn"
import Redirect from "./redirect"

export function Authenticated(props: React.PropsWithChildren<unknown>): JSX.Element {
	const location = useLocation()
	const defaultPeriod = luxon.Duration.fromISO("PT20M")
	const session = sessions.useSession()
	const [loading, setLoading] = useState(true)
	const [refreshTimer, setRefreshTimer] = useState(() => {
		return setInterval(() => {
			console.debug("session refresh timer unset")
		}, defaultPeriod.toMillis() || 1000)
	})

	const refresh = (): CancellablePromise<api.Authn> => {
		const lt = (httpx.qs.parse(location.search)?.lt as string) || ""
		if (lt) {
			return api
				.login(httpx.options.bearer(lt))
				.then(sessions.autoreplace(session, sessions.options.login))
				.then((current) => {
					return current
				})
		}
		const auth = session.credentials === "" ? httpx.options.noop : httpx.options.bearer(session.credentials)
		return api.current(auth).then(sessions.autoreplace(session))
	}

	useEffect(() => {
		clearInterval(refreshTimer)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(refresh)
			.then((current) => {
				setRefreshTimer(setInterval(() => refresh(), defaultPeriod.toMillis() || 1000))
				setLoading(false)
			})
			.catch(
				httpx.errors.notFound((cause) => {
					console.log("session not found", cause)
					setLoading(false)
				}),
			)
			.catch((cause) => {
				console.log("reloading due to being unable to load session", cause)
				setTimeout(() => window.location.reload(), 3000)
			})

		return () => {
			console.debug("authenticated component dismounted", session.id)
			clearInterval(refreshTimer)
			p.cancel()
		}
	}, [session.id]) // eslint-disable-line react-hooks/exhaustive-deps

	if (session.unauthenticated) {
		return (
			<layouts.containers.flex flexDirection="column" height="100vh" width="100vw">
				<layouts.loading.pending loading={loading}>
					<Routes>
						<Route path="/authn/supertokens/verify" element={<authn.Auth authd={authn.emailotp} />} />
						<Route path="/authn/gsuite" element={<authn.Auth authd={authn.gsuite} />} />
						<Route path="/authn/facebook" element={<authn.Auth authd={authn.facebook} />} />
						<Route path="/authn/microsoft" element={<authn.Auth authd={authn.microsoft} />} />
						<Route path="/authn/ephemeral/*" element={<authn.Auth authd={authn.ephemeral} />} />
						<Route path="/authn/s/*" element={<Redirect />} />
						<Route path="*" element={<Redirect />} />
					</Routes>
				</layouts.loading.pending>
			</layouts.containers.flex>
		)
	}

	if (profiles.disabled(session.profile)) {
		return (
			<layouts.containers.flex flexDirection="column" height="100vh" width="100vw">
				<layouts.containers.flex styled m="auto" flexDirection="column" p="10px">
					<typography.h1 mx="auto">Your profile is currently disabled</typography.h1>
					<layouts.containers.flex>
						<typography.h3>
							if you just joined this account, you need to talk to your account administrator to be activated
						</typography.h3>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		)
	}

	return <>{props.children}</>
}
