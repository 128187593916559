import React from "react"
import { useLocation } from "react-router-dom"
import * as layouts from "layouts"
import * as routing from "brandguard/routing"
import { Link } from "react-router-dom"
import * as brands from "brands"
import * as titlebars from "titlebars"

interface props {
	title: string
	urlgen(bid: string): string
}

export function ActiveLink(props: props): JSX.Element {
	const brand = brands.caching.useCached()
	const location = useLocation()
	const link = props.urlgen(brand.id)
	const isactive = location.pathname === link
	return (
		<layouts.containers.flex pr="2px">
			<Link to={link}>
				<titlebars.navigation.Button
					width="120px"
					height="40px"
					disabled={isactive}
					className={`${isactive ? "active" : ""}`}
				>
					{props.title}
				</titlebars.navigation.Button>
			</Link>
		</layouts.containers.flex>
	)
}

export function NavContainer(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.flex
			flexDirection="row"
			borderRadius="10px"
			mb="10px"
			mt="5px"
			background={layouts.theme.colors.white}
			{...rest}
		>
			{children}
		</layouts.containers.flex>
	)
}

export function ImagesNav(): JSX.Element {
	return (
		<NavContainer>
			<ActiveLink title="Scored Assets" urlgen={routing.image.approval.upload} />
			<ActiveLink title="Review Assets" urlgen={routing.image.approval.review} />
			<ActiveLink title="Approved Assets" urlgen={routing.image.approval.approved} />
			<ActiveLink title="Rejected Assets" urlgen={routing.image.approval.rejected} />
		</NavContainer>
	)
}

export function TextsNav(): JSX.Element {
	return (
		<NavContainer>
			<ActiveLink title="Scored Assets" urlgen={routing.text.approval.upload} />
			<ActiveLink title="Review Assets" urlgen={routing.text.approval.review} />
			<ActiveLink title="Approved Assets" urlgen={routing.text.approval.approved} />
			<ActiveLink title="Rejected Assets" urlgen={routing.text.approval.rejected} />
		</NavContainer>
	)
}
