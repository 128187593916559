import React, { useState, useMemo, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as errors from "errors"
import * as brands from "brands"
import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as timex from "timex"
import * as metasearch from "metasearch"
import * as training from "brandguard/display/training"
import * as debugx from "x/debugx"
import ReferenceRules from "./settings.reference.rules"

interface props extends layouts.containers.FlexProps {
	current: api.TextKernelSetting
	onChange(upd: api.TextKernelSetting): void
}

export default function Settings(props: React.PropsWithChildren<props>): JSX.Element {
	const { current, onChange, ...rest } = props
	const brand = brands.caching.useCached()
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(false)

	const admincontrols = useMemo(() => {
		function controls() {
			return (
				<training.layouts.AdminTrainingEnqueue
					enqueue={(ago: timex.DateTime) =>
						api.text.training
							.enqueue(brand.id, { ago: BigInt(ago.toMillis()) }, authzc.bearer(authzaccount))
							.then(() => undefined)
					}
				/>
			)
		}

		return controls
	}, [brand.id])

	const rescore = useMemo(() => {
		function rescore() {
			return (
				<training.layouts.RescoreEnqueue
					enqueue={(ago: timex.DateTime) => {
						return api.text.training
							.rescore(
								brand.id,
								{
									delay: BigInt(timex.duration.seconds(30).toMillis()),
									window: metasearch.dateranges.since(ago),
								},
								authzc.bearer(authzaccount),
							)
							.then(() => undefined)
					}}
				/>
			)
		}

		return rescore
	}, [brand.id])

	useEffect(() => {
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.kernel.config.latest(brand.id, brand.id, authzc.bearer(authzaccount)))
			.then((r) => {
				onChange(api.text.kernel.config.zero({ ...r.settings!, id: uuid.v4() }))
				setLoading(false)
				setCause(undefined)
			})
			.catch(
				httpx.errors.notFound((cause) => {
					console.debug("unable to load text training settings", cause)
					setLoading(false)
					setCause(undefined)
				}),
			)
			.catch((cause) => {
				console.warn(cause)
				setCause(
					<errors.Display cause={cause} onClick={() => setCause(undefined)}>
						failed to load settings
					</errors.Display>,
				)
				setLoading(false)
			})
		return p.cancel
	}, [])

	return (
		<layouts.Flex flexDirection="column" flex="1" color={layouts.theme.colors.grey.dark10} {...rest}>
			{debugx.alpha.enabled() && (
				<layouts.Flex flexDirection="column" mt="20px" gridGap="20px">
					{admincontrols()}
					{rescore()}
				</layouts.Flex>
			)}
			<layouts.Flex my="20px" justifyContent="end">
				<layouts.buttons.primary
					width="10ch"
					borderRadius="5px"
					onClick={() => {
						setLoading(true)
						api.text.kernel
							.create_version(brand.id, brand.id, { settings: current }, authzc.bearer(authzaccount))
							.then((r) => {
								onChange({ ...current, id: uuid.v4() })
								setLoading(false)
								setCause(undefined)
							})
							.catch((cause) => {
								console.warn(cause)
								setCause(
									<errors.Display cause={cause} onClick={() => setCause(undefined)}>
										failed to update settings
									</errors.Display>,
								)
								setLoading(false)
							})
					}}
				>
					Save
				</layouts.buttons.primary>
			</layouts.Flex>
			<layouts.forms.Container key={current.id} loading={loading} cause={cause}>
				{debugx.alpha.enabled() && (
					<training.layouts.styledFormContainer padding="5px 10px">
						<training.layouts.styledFormLabel>Threshold</training.layouts.styledFormLabel>
						<inputs.Numeric
							defaultValue={current.threshold}
							onChange={(evt) =>
								onChange({
									...current,
									threshold: evt.target.valueAsNumber,
								})
							}
							className={training.layouts.styledInput}
							style={{
								marginLeft: "10px",
								maxWidth: "40px",
								background: layouts.theme.backgrounds.bluealpha,
								border: `1px solid ${layouts.theme.colors.grey.dark50alpha10}`,
								minHeight: "30px",
							}}
						/>
					</training.layouts.styledFormContainer>
				)}
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Copy Consistency</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.brand_voice?.enabled}
						onClick={() =>
							onChange({
								...current,
								brand_voice: { ...current.brand_voice, enabled: !current.brand_voice!.enabled },
							})
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Profanity Check</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.profanity!.enabled}
						onClick={() =>
							onChange({ ...current, profanity: { ...current.profanity, enabled: !current.profanity!.enabled } })
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Grammar</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.grammar!.enabled}
						onClick={() =>
							onChange({ ...current, grammar: { ...current.grammar, enabled: !current.grammar!.enabled } })
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Racism Check</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.racism!.enabled}
						onClick={() => onChange({ ...current, racism: { ...current.racism, enabled: !current.racism!.enabled } })}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Tone of Voice</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.tnv!.enabled}
							onClick={() => onChange({ ...current, tnv: { ...current.tnv!, enabled: !current.tnv!.enabled } })}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.Flex flex="1" m="10px">
							<training.layouts.TextArea
								autoFocus
								placeholder="describe the tone and voice"
								default={current.tnv!.prompt}
								onChange={(evt) => onChange({ ...current, tnv: { ...current.tnv!, prompt: evt.target.value } })}
							/>
						</layouts.Flex>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Reference Checks</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.reference_rules!.enabled}
							onClick={() =>
								onChange({
									...current,
									reference_rules: { ...current.reference_rules!, enabled: !current.reference_rules!.enabled },
								})
							}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<ReferenceRules
							settings={current.reference_rules!}
							onChange={(upd: api.TextKernelReferenceRulesEnabled) => {
								onChange({ ...current, reference_rules: upd })
							}}
						/>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
			</layouts.forms.Container>
		</layouts.Flex>
	)
}
