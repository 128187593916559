import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bglayouts from "brandguard/components/layouts"
import * as mediaapi from "media/api"
import * as errors from "errors"

interface props {
	item: api.TextSearchResponseItem
	active?: boolean
	panel: JSX.Element
	onCardClick(): void
	onChecked?(state: boolean): void
	onChange(item: api.TextSearchResponseItem): void
	find(id: string, bid: string, ...options: httpx.option[]): Promise<api.TextFindResponse>
	checked: boolean
	score: JSX.Element
}

export default function Display(
	props: React.PropsWithChildren<props & layouts.containers.ContainerProps>,
): JSX.Element {
	const { item, active, onChange, onCardClick, onChecked, find, panel, checked, className, score, ...rest } = props
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [response, setResponse] = useState("")
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const processing = predictions.processing(item.event?.approved)

	useEffect(() => {
		if (!processing) return
		let cancelled = false
		const tid = setTimeout(() => {
			find(item.media?.id!, brand.id, bearertoken)
				.then((result) => {
					cancelled || onChange(result)
				})
				.catch((cause) => {
					console.error("unable to refresh brandguard approval text display", cause)
					cancelled || onChange({ ...item })
				})
		}, Math.round(Math.random() * 5000 + 5000))

		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [item])

	useEffect(() => {
		setLoading(true)
		mediaapi
			.url(item.media?.md5!, bearertoken)
			.then((result) => httpx._fetch(result.url))
			.then((resp) => resp.text())
			.then((data) => setResponse(data))
			.catch(() =>
				setCause(
					<errors.Inline flex="1" display="flex">
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							unable to retrieve asset
						</errors.Textual>
					</errors.Inline>,
				),
			)
			.finally(() => setLoading(false))
	}, [item.media?.md5])

	return (
		<bglayouts.Card
			key={item.media?.id}
			className={`${className} ${active ? "active" : ""}`}
			flexDirection="column"
			marginBottom="unset"
			{...rest}
		>
			<layouts.loading.screen loading={loading} mt="auto" width="100%">
				<bglayouts.CardTextContent
					onClick={onCardClick}
					opacity={processing ? 0.3 : 1}
					className={`${className} card-text-content`}
					background={layouts.theme.colors.grey.dark50alpha05}
				>
					{cause ? cause : response}
				</bglayouts.CardTextContent>
				{score}
			</layouts.loading.screen>
			{onChecked && (
				<icons.brandguard.checkbox
					position="absolute"
					bottom="10px"
					right="10px"
					onClick={(e) => {
						onChecked(!checked)
						e.stopPropagation()
					}}
					checked={checked}
				/>
			)}
			{panel}
		</bglayouts.Card>
	)
}

Display.defaultProps = {
	checked: false,
	score: <></>,
}
