import React, { useState, useContext } from "react"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as meta from "meta/authz"
import * as icons from "icons"
import * as context from "./context"
import * as caching from "./cache"
import * as links from "./links"
import { css } from "@emotion/css"

export const styling = css`
	.navigation > *:not(:last-child) {
		margin-bottom: 25px;
	}

	.navigation > * {
		display: table;
	}
`

interface props {
	bid: string
	collapsed: boolean
	permission: meta.Token
}
function BrandsNavigation(props: props): JSX.Element {
	const { bid, permission, collapsed } = props
	if (bid === uuid.NIL) return <></>
	return (
		<>
			<links.BrandGuide
				bid={bid}
				collapsed={collapsed}
				permissions={permission.brandguide_view || permission.brandguide_edit}
			/>
			<links.AssetsTraining bid={bid} collapsed={collapsed} permissions={permission.brandguard_train} />
			<links.BrandGuard bid={bid} collapsed={collapsed} />
			<links.Approvals bid={bid} collapsed={collapsed} />
			<links.Metrics bid={bid} collapsed={collapsed} />
			<links.BrandGPT bid={bid} collapsed={collapsed} permissions={permission.brandgpt_use} />
		</>
	)
}

export function Inner(props: layouts.containers.FlexProps): JSX.Element {
	const { collapsed, collapse, bid, hidden } = useContext(context.Context)
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))
	const permission = metaauthz.current

	return (
		<layouts.containers.flex
			styled
			className={styling}
			minWidth={collapsed ? "40px" : "250px"}
			background={layouts.theme.colors.white}
			display={hidden ? "none" : "flex"}
			p="20px"
			{...props}
		>
			<layouts.containers.flex
				flexDirection="column"
				flex="1"
				minHeight="max-content"
				className="navigation"
				overflowY="auto"
				overflowX="hidden"
			>
				<links.LogoLink collapsed={collapsed} />
				<layouts.containers.box
					textAlign="left"
					pb="50px"
					ml="5px"
					onClick={() => {
						collapse(!collapsed)
					}}
				>
					<icons.MenuHamburger fill={layouts.theme.colors.grey.dark10} width="25px" />
				</layouts.containers.box>
				<links.Brands bid={bid} collapsed={collapsed} />
				<BrandsNavigation bid={bid} permission={permission} collapsed={collapsed} />
				<links.Settings bid={bid} collapsed={collapsed} permissions={permission.usermanagement} />
				<layouts.containers.box mt="auto" />
				<links.Logout bid={bid} collapsed={collapsed} />
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export default function Standard(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children } = props
	const [root, setRoot] = useState(context.zero({ collapsed: caching.cache.get("collapsed") }))

	const ctx = {
		...root,
		collapse(upd: boolean) {
			setRoot({ ...ctx, collapsed: upd })
			caching.cache.set("collapsed", upd)
		},
		update(d: Partial<context.ctx>) {
			setRoot({ ...ctx, ...d })
		},
	}

	return (
		<context.Provider value={ctx}>
			<Inner {...props} />
			{children}
		</context.Provider>
	)
}
