/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.image.proto" (package "brandguard.image", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ImageKernelSetting } from "./brandguard.kernel_pb";
import { KernelMetadata } from "./brandguard.kernel_pb";
import { FloatRange } from "./meta.search_pb";
import { DateRange } from "./meta.search_pb";
import { Prediction } from "./brandguard.approval_pb";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageEvent
 */
export interface ImageEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: brandguard.approval.Prediction approved = 4;
     */
    approved: Prediction;
    /**
     * @generated from protobuf field: brandguard.approval.Prediction observation = 5;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: string queue = 6;
     */
    queue: string;
    /**
     * @generated from protobuf field: double confidence = 1000;
     */
    confidence: number;
    /**
     * @generated from protobuf field: double brand_uniqueness = 1004 [json_name = "brand_uniqueness"];
     */
    brand_uniqueness: number;
    /**
     * @generated from protobuf field: double brand_voice = 1005 [json_name = "brand_voice"];
     */
    brand_voice: number;
    /**
     * @generated from protobuf field: double sexually_explicit = 1006 [json_name = "sexually_explicit"];
     */
    sexually_explicit: number;
    /**
     * @generated from protobuf field: double image_quality_general = 1007 [json_name = "quality_general"];
     */
    image_quality_general: number;
    /**
     * @generated from protobuf field: double image_violence = 1008 [json_name = "image_violence"];
     */
    image_violence: number;
    /**
     * @generated from protobuf field: double image_logo_detection = 1009 [json_name = "image_logo_detection"];
     */
    image_logo_detection: number;
    /**
     * @generated from protobuf field: double image_logo_colors = 1010 [json_name = "image_logo_colors"];
     */
    image_logo_colors: number;
    /**
     * @generated from protobuf field: double image_logo_distortion = 1011 [json_name = "image_logo_distortion"];
     */
    image_logo_distortion: number;
    /**
     * @generated from protobuf field: int64 image_obscure_rule_checks = 1012 [json_name = "image_obscure_rule_checks"];
     */
    image_obscure_rule_checks: bigint;
    /**
     * @generated from protobuf field: int64 image_obscure_rule_violations = 1013 [json_name = "image_obscure_rule_violations"];
     */
    image_obscure_rule_violations: bigint;
    /**
     * @generated from protobuf field: double image_obscure_rule_conformance = 1014 [json_name = "image_obscure_rule_conformance"];
     */
    image_obscure_rule_conformance: number;
    /**
     * @generated from protobuf field: double image_font_detection = 1015 [json_name = "image_font_detection"];
     */
    image_font_detection: number;
    /**
     * @generated from protobuf field: double image_logo_clearspace = 1016 [json_name = "image_logo_clearspace"];
     */
    image_logo_clearspace: number;
    /**
     * @generated from protobuf field: double image_background_colors = 1017 [json_name = "image_background_colors"];
     */
    image_background_colors: number;
    /**
     * @generated from protobuf field: string updated_at = 1018 [json_name = "updated_at"];
     */
    updated_at: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageMedia
 */
export interface ImageMedia {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string uploaded_by = 4 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string md5 = 5;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 6;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string mimetype_md5 = 7 [json_name = "mimetype_md5"];
     */
    mimetype_md5: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 10 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string scored_at = 11 [json_name = "scored_at"];
     */
    scored_at: string;
    /**
     * @generated from protobuf field: string description = 12;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageUploadRequest
 */
export interface ImageUploadRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageUploadResponse
 */
export interface ImageUploadResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageMediaUploadRequest
 */
export interface ImageMediaUploadRequest {
    /**
     * @generated from protobuf field: repeated string md5s = 1 [json_name = "md5s"];
     */
    md5s: string[];
    /**
     * @generated from protobuf field: brandguard.approval.Prediction observation = 2;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: int64 delay = 3;
     */
    delay: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageMediaUploadResponse
 */
export interface ImageMediaUploadResponse {
    /**
     * @generated from protobuf field: repeated brandguard.image.ImageUploadResponse items = 1;
     */
    items: ImageUploadResponse[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchRequest
 */
export interface ImageSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string queues = 2;
     */
    queues: string[]; // only applies to uploaded content
    /**
     * @deprecated
     * @generated from protobuf field: string deprecated_uploaded_by = 3 [deprecated = true, json_name = "uploaded_by"];
     */
    deprecated_uploaded_by: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
    /**
     * @generated from protobuf field: repeated brandguard.approval.Prediction observation = 7;
     */
    observation: Prediction[];
    /**
     * @generated from protobuf field: repeated brandguard.approval.Prediction approved = 8;
     */
    approved: Prediction[];
    /**
     * @generated from protobuf field: meta.FloatRange confidence = 9;
     */
    confidence?: FloatRange;
    /**
     * @generated from protobuf field: repeated string uploaded_by = 10 [json_name = "uploaders"];
     */
    uploaded_by: string[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchResponseItem
 */
export interface ImageSearchResponseItem {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageSearchResponse
 */
export interface ImageSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.image.ImageSearchResponseItem items = 1;
     */
    items: ImageSearchResponseItem[];
    /**
     * @generated from protobuf field: brandguard.image.ImageSearchRequest next = 2;
     */
    next?: ImageSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageFindRequest
 */
export interface ImageFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageFindResponse
 */
export interface ImageFindResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageScoreRequest
 */
export interface ImageScoreRequest {
}
/**
 * @generated from protobuf message brandguard.image.ImageScoreResponse
 */
export interface ImageScoreResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImagePatchRequest
 */
export interface ImagePatchRequest {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImagePatchResponse
 */
export interface ImagePatchResponse {
    /**
     * @generated from protobuf field: brandguard.image.ImageMedia media = 1;
     */
    media?: ImageMedia;
    /**
     * @generated from protobuf field: brandguard.image.ImageEvent event = 2;
     */
    event?: ImageEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageTrainRequest
 */
export interface ImageTrainRequest {
    /**
     * @generated from protobuf field: int64 ago = 1;
     */
    ago: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageTrainResponse
 */
export interface ImageTrainResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSearchRequest
 */
export interface ImageKernelSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSearchResponse
 */
export interface ImageKernelSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.kernel.KernelMetadata items = 1;
     */
    items: KernelMetadata[];
    /**
     * @generated from protobuf field: brandguard.image.ImageKernelSearchRequest next = 2;
     */
    next?: ImageKernelSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelFindRequest
 */
export interface ImageKernelFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelFindResponse
 */
export interface ImageKernelFindResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.KernelMetadata kernel = 1;
     */
    kernel?: KernelMetadata;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSettingsCreateRequest
 */
export interface ImageKernelSettingsCreateRequest {
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelSetting settings = 1;
     */
    settings?: ImageKernelSetting;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSettingsCreateResponse
 */
export interface ImageKernelSettingsCreateResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.KernelMetadata kernel = 1;
     */
    kernel?: KernelMetadata;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSettingsRequest
 */
export interface ImageKernelSettingsRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageKernelSettingsResponse
 */
export interface ImageKernelSettingsResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelSetting settings = 1;
     */
    settings?: ImageKernelSetting;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageRescoreRequest
 */
export interface ImageRescoreRequest {
    /**
     * @generated from protobuf field: int64 delay = 1;
     */
    delay: bigint;
    /**
     * @generated from protobuf field: meta.DateRange window = 2;
     */
    window?: DateRange;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImageRescoreResponse
 */
export interface ImageRescoreResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImportFromURLRequest
 */
export interface ImportFromURLRequest {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.ImportFromURLResponse
 */
export interface ImportFromURLResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.StockImportRequest
 */
export interface StockImportRequest {
    /**
     * @generated from protobuf field: string brand_id = 1 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: int32 number = 2;
     */
    number: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.StockImportResponse
 */
export interface StockImportResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.GoogleImagesRequest
 */
export interface GoogleImagesRequest {
    /**
     * @generated from protobuf field: string keywords = 1;
     */
    keywords: string;
    /**
     * @generated from protobuf field: int32 number = 2;
     */
    number: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.image.GoogleImagesResponse
 */
export interface GoogleImagesResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class ImageEvent$Type extends MessageType<ImageEvent> {
    constructor() {
        super("brandguard.image.ImageEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "approved", kind: "enum", T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 5, name: "observation", kind: "enum", T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 6, name: "queue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1000, name: "confidence", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1004, name: "brand_uniqueness", kind: "scalar", localName: "brand_uniqueness", jsonName: "brand_uniqueness", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1005, name: "brand_voice", kind: "scalar", localName: "brand_voice", jsonName: "brand_voice", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1006, name: "sexually_explicit", kind: "scalar", localName: "sexually_explicit", jsonName: "sexually_explicit", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1007, name: "image_quality_general", kind: "scalar", localName: "image_quality_general", jsonName: "quality_general", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1008, name: "image_violence", kind: "scalar", localName: "image_violence", jsonName: "image_violence", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1009, name: "image_logo_detection", kind: "scalar", localName: "image_logo_detection", jsonName: "image_logo_detection", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1010, name: "image_logo_colors", kind: "scalar", localName: "image_logo_colors", jsonName: "image_logo_colors", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1011, name: "image_logo_distortion", kind: "scalar", localName: "image_logo_distortion", jsonName: "image_logo_distortion", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1012, name: "image_obscure_rule_checks", kind: "scalar", localName: "image_obscure_rule_checks", jsonName: "image_obscure_rule_checks", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 1013, name: "image_obscure_rule_violations", kind: "scalar", localName: "image_obscure_rule_violations", jsonName: "image_obscure_rule_violations", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 1014, name: "image_obscure_rule_conformance", kind: "scalar", localName: "image_obscure_rule_conformance", jsonName: "image_obscure_rule_conformance", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1015, name: "image_font_detection", kind: "scalar", localName: "image_font_detection", jsonName: "image_font_detection", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1016, name: "image_logo_clearspace", kind: "scalar", localName: "image_logo_clearspace", jsonName: "image_logo_clearspace", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1017, name: "image_background_colors", kind: "scalar", localName: "image_background_colors", jsonName: "image_background_colors", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1018, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageEvent>): ImageEvent {
        const message = { id: "", account_id: "", brand_id: "", approved: 0, observation: 0, queue: "", confidence: 0, brand_uniqueness: 0, brand_voice: 0, sexually_explicit: 0, image_quality_general: 0, image_violence: 0, image_logo_detection: 0, image_logo_colors: 0, image_logo_distortion: 0, image_obscure_rule_checks: 0n, image_obscure_rule_violations: 0n, image_obscure_rule_conformance: 0, image_font_detection: 0, image_logo_clearspace: 0, image_background_colors: 0, updated_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageEvent): ImageEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* brandguard.approval.Prediction approved */ 4:
                    message.approved = reader.int32();
                    break;
                case /* brandguard.approval.Prediction observation */ 5:
                    message.observation = reader.int32();
                    break;
                case /* string queue */ 6:
                    message.queue = reader.string();
                    break;
                case /* double confidence */ 1000:
                    message.confidence = reader.double();
                    break;
                case /* double brand_uniqueness = 1004 [json_name = "brand_uniqueness"];*/ 1004:
                    message.brand_uniqueness = reader.double();
                    break;
                case /* double brand_voice = 1005 [json_name = "brand_voice"];*/ 1005:
                    message.brand_voice = reader.double();
                    break;
                case /* double sexually_explicit = 1006 [json_name = "sexually_explicit"];*/ 1006:
                    message.sexually_explicit = reader.double();
                    break;
                case /* double image_quality_general = 1007 [json_name = "quality_general"];*/ 1007:
                    message.image_quality_general = reader.double();
                    break;
                case /* double image_violence = 1008 [json_name = "image_violence"];*/ 1008:
                    message.image_violence = reader.double();
                    break;
                case /* double image_logo_detection = 1009 [json_name = "image_logo_detection"];*/ 1009:
                    message.image_logo_detection = reader.double();
                    break;
                case /* double image_logo_colors = 1010 [json_name = "image_logo_colors"];*/ 1010:
                    message.image_logo_colors = reader.double();
                    break;
                case /* double image_logo_distortion = 1011 [json_name = "image_logo_distortion"];*/ 1011:
                    message.image_logo_distortion = reader.double();
                    break;
                case /* int64 image_obscure_rule_checks = 1012 [json_name = "image_obscure_rule_checks"];*/ 1012:
                    message.image_obscure_rule_checks = reader.int64().toBigInt();
                    break;
                case /* int64 image_obscure_rule_violations = 1013 [json_name = "image_obscure_rule_violations"];*/ 1013:
                    message.image_obscure_rule_violations = reader.int64().toBigInt();
                    break;
                case /* double image_obscure_rule_conformance = 1014 [json_name = "image_obscure_rule_conformance"];*/ 1014:
                    message.image_obscure_rule_conformance = reader.double();
                    break;
                case /* double image_font_detection = 1015 [json_name = "image_font_detection"];*/ 1015:
                    message.image_font_detection = reader.double();
                    break;
                case /* double image_logo_clearspace = 1016 [json_name = "image_logo_clearspace"];*/ 1016:
                    message.image_logo_clearspace = reader.double();
                    break;
                case /* double image_background_colors = 1017 [json_name = "image_background_colors"];*/ 1017:
                    message.image_background_colors = reader.double();
                    break;
                case /* string updated_at = 1018 [json_name = "updated_at"];*/ 1018:
                    message.updated_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* brandguard.approval.Prediction approved = 4; */
        if (message.approved !== 0)
            writer.tag(4, WireType.Varint).int32(message.approved);
        /* brandguard.approval.Prediction observation = 5; */
        if (message.observation !== 0)
            writer.tag(5, WireType.Varint).int32(message.observation);
        /* string queue = 6; */
        if (message.queue !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.queue);
        /* double confidence = 1000; */
        if (message.confidence !== 0)
            writer.tag(1000, WireType.Bit64).double(message.confidence);
        /* double brand_uniqueness = 1004 [json_name = "brand_uniqueness"]; */
        if (message.brand_uniqueness !== 0)
            writer.tag(1004, WireType.Bit64).double(message.brand_uniqueness);
        /* double brand_voice = 1005 [json_name = "brand_voice"]; */
        if (message.brand_voice !== 0)
            writer.tag(1005, WireType.Bit64).double(message.brand_voice);
        /* double sexually_explicit = 1006 [json_name = "sexually_explicit"]; */
        if (message.sexually_explicit !== 0)
            writer.tag(1006, WireType.Bit64).double(message.sexually_explicit);
        /* double image_quality_general = 1007 [json_name = "quality_general"]; */
        if (message.image_quality_general !== 0)
            writer.tag(1007, WireType.Bit64).double(message.image_quality_general);
        /* double image_violence = 1008 [json_name = "image_violence"]; */
        if (message.image_violence !== 0)
            writer.tag(1008, WireType.Bit64).double(message.image_violence);
        /* double image_logo_detection = 1009 [json_name = "image_logo_detection"]; */
        if (message.image_logo_detection !== 0)
            writer.tag(1009, WireType.Bit64).double(message.image_logo_detection);
        /* double image_logo_colors = 1010 [json_name = "image_logo_colors"]; */
        if (message.image_logo_colors !== 0)
            writer.tag(1010, WireType.Bit64).double(message.image_logo_colors);
        /* double image_logo_distortion = 1011 [json_name = "image_logo_distortion"]; */
        if (message.image_logo_distortion !== 0)
            writer.tag(1011, WireType.Bit64).double(message.image_logo_distortion);
        /* int64 image_obscure_rule_checks = 1012 [json_name = "image_obscure_rule_checks"]; */
        if (message.image_obscure_rule_checks !== 0n)
            writer.tag(1012, WireType.Varint).int64(message.image_obscure_rule_checks);
        /* int64 image_obscure_rule_violations = 1013 [json_name = "image_obscure_rule_violations"]; */
        if (message.image_obscure_rule_violations !== 0n)
            writer.tag(1013, WireType.Varint).int64(message.image_obscure_rule_violations);
        /* double image_obscure_rule_conformance = 1014 [json_name = "image_obscure_rule_conformance"]; */
        if (message.image_obscure_rule_conformance !== 0)
            writer.tag(1014, WireType.Bit64).double(message.image_obscure_rule_conformance);
        /* double image_font_detection = 1015 [json_name = "image_font_detection"]; */
        if (message.image_font_detection !== 0)
            writer.tag(1015, WireType.Bit64).double(message.image_font_detection);
        /* double image_logo_clearspace = 1016 [json_name = "image_logo_clearspace"]; */
        if (message.image_logo_clearspace !== 0)
            writer.tag(1016, WireType.Bit64).double(message.image_logo_clearspace);
        /* double image_background_colors = 1017 [json_name = "image_background_colors"]; */
        if (message.image_background_colors !== 0)
            writer.tag(1017, WireType.Bit64).double(message.image_background_colors);
        /* string updated_at = 1018 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(1018, WireType.LengthDelimited).string(message.updated_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageEvent
 */
export const ImageEvent = new ImageEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageMedia$Type extends MessageType<ImageMedia> {
    constructor() {
        super("brandguard.image.ImageMedia", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mimetype_md5", kind: "scalar", localName: "mimetype_md5", jsonName: "mimetype_md5", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "scored_at", kind: "scalar", localName: "scored_at", jsonName: "scored_at", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageMedia>): ImageMedia {
        const message = { id: "", account_id: "", brand_id: "", uploaded_by: "", md5: "", mimetype: "", mimetype_md5: "", created_at: "", updated_at: "", tombstoned_at: "", scored_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageMedia): ImageMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string uploaded_by = 4 [json_name = "uploaded_by"];*/ 4:
                    message.uploaded_by = reader.string();
                    break;
                case /* string md5 */ 5:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 6:
                    message.mimetype = reader.string();
                    break;
                case /* string mimetype_md5 = 7 [json_name = "mimetype_md5"];*/ 7:
                    message.mimetype_md5 = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 10 [json_name = "tombstoned_at"];*/ 10:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string scored_at = 11 [json_name = "scored_at"];*/ 11:
                    message.scored_at = reader.string();
                    break;
                case /* string description */ 12:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string uploaded_by = 4 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploaded_by);
        /* string md5 = 5; */
        if (message.md5 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 6; */
        if (message.mimetype !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimetype);
        /* string mimetype_md5 = 7 [json_name = "mimetype_md5"]; */
        if (message.mimetype_md5 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimetype_md5);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 10 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string scored_at = 11 [json_name = "scored_at"]; */
        if (message.scored_at !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.scored_at);
        /* string description = 12; */
        if (message.description !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageMedia
 */
export const ImageMedia = new ImageMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageUploadRequest$Type extends MessageType<ImageUploadRequest> {
    constructor() {
        super("brandguard.image.ImageUploadRequest", []);
    }
    create(value?: PartialMessage<ImageUploadRequest>): ImageUploadRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageUploadRequest): ImageUploadRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageUploadRequest
 */
export const ImageUploadRequest = new ImageUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageUploadResponse$Type extends MessageType<ImageUploadResponse> {
    constructor() {
        super("brandguard.image.ImageUploadResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageUploadResponse>): ImageUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageUploadResponse): ImageUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageUploadResponse
 */
export const ImageUploadResponse = new ImageUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageMediaUploadRequest$Type extends MessageType<ImageMediaUploadRequest> {
    constructor() {
        super("brandguard.image.ImageMediaUploadRequest", [
            { no: 1, name: "md5s", kind: "scalar", localName: "md5s", jsonName: "md5s", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "observation", kind: "enum", T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 3, name: "delay", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ImageMediaUploadRequest>): ImageMediaUploadRequest {
        const message = { md5s: [], observation: 0, delay: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageMediaUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageMediaUploadRequest): ImageMediaUploadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string md5s = 1 [json_name = "md5s"];*/ 1:
                    message.md5s.push(reader.string());
                    break;
                case /* brandguard.approval.Prediction observation */ 2:
                    message.observation = reader.int32();
                    break;
                case /* int64 delay */ 3:
                    message.delay = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageMediaUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string md5s = 1 [json_name = "md5s"]; */
        for (let i = 0; i < message.md5s.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.md5s[i]);
        /* brandguard.approval.Prediction observation = 2; */
        if (message.observation !== 0)
            writer.tag(2, WireType.Varint).int32(message.observation);
        /* int64 delay = 3; */
        if (message.delay !== 0n)
            writer.tag(3, WireType.Varint).int64(message.delay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageMediaUploadRequest
 */
export const ImageMediaUploadRequest = new ImageMediaUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageMediaUploadResponse$Type extends MessageType<ImageMediaUploadResponse> {
    constructor() {
        super("brandguard.image.ImageMediaUploadResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageUploadResponse }
        ]);
    }
    create(value?: PartialMessage<ImageMediaUploadResponse>): ImageMediaUploadResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageMediaUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageMediaUploadResponse): ImageMediaUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.image.ImageUploadResponse items */ 1:
                    message.items.push(ImageUploadResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageMediaUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.image.ImageUploadResponse items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ImageUploadResponse.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageMediaUploadResponse
 */
export const ImageMediaUploadResponse = new ImageMediaUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchRequest$Type extends MessageType<ImageSearchRequest> {
    constructor() {
        super("brandguard.image.ImageSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "queues", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deprecated_uploaded_by", kind: "scalar", localName: "deprecated_uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "observation", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 8, name: "approved", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 9, name: "confidence", kind: "message", T: () => FloatRange },
            { no: 10, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaders", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageSearchRequest>): ImageSearchRequest {
        const message = { offset: 0n, queues: [], deprecated_uploaded_by: "", brand_id: "", limit: 0n, observation: [], approved: [], uploaded_by: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchRequest): ImageSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string queues */ 2:
                    message.queues.push(reader.string());
                    break;
                case /* string deprecated_uploaded_by = 3 [deprecated = true, json_name = "uploaded_by"];*/ 3:
                    message.deprecated_uploaded_by = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                case /* repeated brandguard.approval.Prediction observation */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.observation.push(reader.int32());
                    else
                        message.observation.push(reader.int32());
                    break;
                case /* repeated brandguard.approval.Prediction approved */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.approved.push(reader.int32());
                    else
                        message.approved.push(reader.int32());
                    break;
                case /* meta.FloatRange confidence */ 9:
                    message.confidence = FloatRange.internalBinaryRead(reader, reader.uint32(), options, message.confidence);
                    break;
                case /* repeated string uploaded_by = 10 [json_name = "uploaders"];*/ 10:
                    message.uploaded_by.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string queues = 2; */
        for (let i = 0; i < message.queues.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.queues[i]);
        /* string deprecated_uploaded_by = 3 [deprecated = true, json_name = "uploaded_by"]; */
        if (message.deprecated_uploaded_by !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deprecated_uploaded_by);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        /* repeated brandguard.approval.Prediction observation = 7; */
        if (message.observation.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.observation.length; i++)
                writer.int32(message.observation[i]);
            writer.join();
        }
        /* repeated brandguard.approval.Prediction approved = 8; */
        if (message.approved.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.approved.length; i++)
                writer.int32(message.approved[i]);
            writer.join();
        }
        /* meta.FloatRange confidence = 9; */
        if (message.confidence)
            FloatRange.internalBinaryWrite(message.confidence, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated string uploaded_by = 10 [json_name = "uploaders"]; */
        for (let i = 0; i < message.uploaded_by.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.uploaded_by[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchRequest
 */
export const ImageSearchRequest = new ImageSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchResponseItem$Type extends MessageType<ImageSearchResponseItem> {
    constructor() {
        super("brandguard.image.ImageSearchResponseItem", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageSearchResponseItem>): ImageSearchResponseItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchResponseItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchResponseItem): ImageSearchResponseItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchResponseItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchResponseItem
 */
export const ImageSearchResponseItem = new ImageSearchResponseItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSearchResponse$Type extends MessageType<ImageSearchResponse> {
    constructor() {
        super("brandguard.image.ImageSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageSearchResponseItem },
            { no: 2, name: "next", kind: "message", T: () => ImageSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ImageSearchResponse>): ImageSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageSearchResponse): ImageSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.image.ImageSearchResponseItem items */ 1:
                    message.items.push(ImageSearchResponseItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.image.ImageSearchRequest next */ 2:
                    message.next = ImageSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.image.ImageSearchResponseItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ImageSearchResponseItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageSearchRequest next = 2; */
        if (message.next)
            ImageSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageSearchResponse
 */
export const ImageSearchResponse = new ImageSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageFindRequest$Type extends MessageType<ImageFindRequest> {
    constructor() {
        super("brandguard.image.ImageFindRequest", []);
    }
    create(value?: PartialMessage<ImageFindRequest>): ImageFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageFindRequest): ImageFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageFindRequest
 */
export const ImageFindRequest = new ImageFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageFindResponse$Type extends MessageType<ImageFindResponse> {
    constructor() {
        super("brandguard.image.ImageFindResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImageFindResponse>): ImageFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageFindResponse): ImageFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageFindResponse
 */
export const ImageFindResponse = new ImageFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageScoreRequest$Type extends MessageType<ImageScoreRequest> {
    constructor() {
        super("brandguard.image.ImageScoreRequest", []);
    }
    create(value?: PartialMessage<ImageScoreRequest>): ImageScoreRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageScoreRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageScoreRequest): ImageScoreRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageScoreRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageScoreRequest
 */
export const ImageScoreRequest = new ImageScoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageScoreResponse$Type extends MessageType<ImageScoreResponse> {
    constructor() {
        super("brandguard.image.ImageScoreResponse", []);
    }
    create(value?: PartialMessage<ImageScoreResponse>): ImageScoreResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageScoreResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageScoreResponse): ImageScoreResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageScoreResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageScoreResponse
 */
export const ImageScoreResponse = new ImageScoreResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagePatchRequest$Type extends MessageType<ImagePatchRequest> {
    constructor() {
        super("brandguard.image.ImagePatchRequest", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImagePatchRequest>): ImagePatchRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagePatchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagePatchRequest): ImagePatchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImagePatchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImagePatchRequest
 */
export const ImagePatchRequest = new ImagePatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagePatchResponse$Type extends MessageType<ImagePatchResponse> {
    constructor() {
        super("brandguard.image.ImagePatchResponse", [
            { no: 1, name: "media", kind: "message", T: () => ImageMedia },
            { no: 2, name: "event", kind: "message", T: () => ImageEvent }
        ]);
    }
    create(value?: PartialMessage<ImagePatchResponse>): ImagePatchResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagePatchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagePatchResponse): ImagePatchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.image.ImageMedia media */ 1:
                    message.media = ImageMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.image.ImageEvent event */ 2:
                    message.event = ImageEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImagePatchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.image.ImageMedia media = 1; */
        if (message.media)
            ImageMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageEvent event = 2; */
        if (message.event)
            ImageEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImagePatchResponse
 */
export const ImagePatchResponse = new ImagePatchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageTrainRequest$Type extends MessageType<ImageTrainRequest> {
    constructor() {
        super("brandguard.image.ImageTrainRequest", [
            { no: 1, name: "ago", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ImageTrainRequest>): ImageTrainRequest {
        const message = { ago: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageTrainRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageTrainRequest): ImageTrainRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ago */ 1:
                    message.ago = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageTrainRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ago = 1; */
        if (message.ago !== 0n)
            writer.tag(1, WireType.Varint).int64(message.ago);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageTrainRequest
 */
export const ImageTrainRequest = new ImageTrainRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageTrainResponse$Type extends MessageType<ImageTrainResponse> {
    constructor() {
        super("brandguard.image.ImageTrainResponse", []);
    }
    create(value?: PartialMessage<ImageTrainResponse>): ImageTrainResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageTrainResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageTrainResponse): ImageTrainResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageTrainResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageTrainResponse
 */
export const ImageTrainResponse = new ImageTrainResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSearchRequest$Type extends MessageType<ImageKernelSearchRequest> {
    constructor() {
        super("brandguard.image.ImageKernelSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSearchRequest>): ImageKernelSearchRequest {
        const message = { offset: 0n, brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSearchRequest): ImageKernelSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSearchRequest
 */
export const ImageKernelSearchRequest = new ImageKernelSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSearchResponse$Type extends MessageType<ImageKernelSearchResponse> {
    constructor() {
        super("brandguard.image.ImageKernelSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KernelMetadata },
            { no: 2, name: "next", kind: "message", T: () => ImageKernelSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSearchResponse>): ImageKernelSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSearchResponse): ImageKernelSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.kernel.KernelMetadata items */ 1:
                    message.items.push(KernelMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.image.ImageKernelSearchRequest next */ 2:
                    message.next = ImageKernelSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.kernel.KernelMetadata items = 1; */
        for (let i = 0; i < message.items.length; i++)
            KernelMetadata.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.image.ImageKernelSearchRequest next = 2; */
        if (message.next)
            ImageKernelSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSearchResponse
 */
export const ImageKernelSearchResponse = new ImageKernelSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelFindRequest$Type extends MessageType<ImageKernelFindRequest> {
    constructor() {
        super("brandguard.image.ImageKernelFindRequest", []);
    }
    create(value?: PartialMessage<ImageKernelFindRequest>): ImageKernelFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelFindRequest): ImageKernelFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageKernelFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelFindRequest
 */
export const ImageKernelFindRequest = new ImageKernelFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelFindResponse$Type extends MessageType<ImageKernelFindResponse> {
    constructor() {
        super("brandguard.image.ImageKernelFindResponse", [
            { no: 1, name: "kernel", kind: "message", T: () => KernelMetadata }
        ]);
    }
    create(value?: PartialMessage<ImageKernelFindResponse>): ImageKernelFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelFindResponse): ImageKernelFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.KernelMetadata kernel */ 1:
                    message.kernel = KernelMetadata.internalBinaryRead(reader, reader.uint32(), options, message.kernel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.KernelMetadata kernel = 1; */
        if (message.kernel)
            KernelMetadata.internalBinaryWrite(message.kernel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelFindResponse
 */
export const ImageKernelFindResponse = new ImageKernelFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSettingsCreateRequest$Type extends MessageType<ImageKernelSettingsCreateRequest> {
    constructor() {
        super("brandguard.image.ImageKernelSettingsCreateRequest", [
            { no: 1, name: "settings", kind: "message", T: () => ImageKernelSetting }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSettingsCreateRequest>): ImageKernelSettingsCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSettingsCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSettingsCreateRequest): ImageKernelSettingsCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.ImageKernelSetting settings */ 1:
                    message.settings = ImageKernelSetting.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSettingsCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.ImageKernelSetting settings = 1; */
        if (message.settings)
            ImageKernelSetting.internalBinaryWrite(message.settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSettingsCreateRequest
 */
export const ImageKernelSettingsCreateRequest = new ImageKernelSettingsCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSettingsCreateResponse$Type extends MessageType<ImageKernelSettingsCreateResponse> {
    constructor() {
        super("brandguard.image.ImageKernelSettingsCreateResponse", [
            { no: 1, name: "kernel", kind: "message", T: () => KernelMetadata }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSettingsCreateResponse>): ImageKernelSettingsCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSettingsCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSettingsCreateResponse): ImageKernelSettingsCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.KernelMetadata kernel */ 1:
                    message.kernel = KernelMetadata.internalBinaryRead(reader, reader.uint32(), options, message.kernel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSettingsCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.KernelMetadata kernel = 1; */
        if (message.kernel)
            KernelMetadata.internalBinaryWrite(message.kernel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSettingsCreateResponse
 */
export const ImageKernelSettingsCreateResponse = new ImageKernelSettingsCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSettingsRequest$Type extends MessageType<ImageKernelSettingsRequest> {
    constructor() {
        super("brandguard.image.ImageKernelSettingsRequest", []);
    }
    create(value?: PartialMessage<ImageKernelSettingsRequest>): ImageKernelSettingsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSettingsRequest): ImageKernelSettingsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageKernelSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSettingsRequest
 */
export const ImageKernelSettingsRequest = new ImageKernelSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSettingsResponse$Type extends MessageType<ImageKernelSettingsResponse> {
    constructor() {
        super("brandguard.image.ImageKernelSettingsResponse", [
            { no: 1, name: "settings", kind: "message", T: () => ImageKernelSetting }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSettingsResponse>): ImageKernelSettingsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSettingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSettingsResponse): ImageKernelSettingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.ImageKernelSetting settings */ 1:
                    message.settings = ImageKernelSetting.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSettingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.ImageKernelSetting settings = 1; */
        if (message.settings)
            ImageKernelSetting.internalBinaryWrite(message.settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageKernelSettingsResponse
 */
export const ImageKernelSettingsResponse = new ImageKernelSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageRescoreRequest$Type extends MessageType<ImageRescoreRequest> {
    constructor() {
        super("brandguard.image.ImageRescoreRequest", [
            { no: 1, name: "delay", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "window", kind: "message", T: () => DateRange }
        ]);
    }
    create(value?: PartialMessage<ImageRescoreRequest>): ImageRescoreRequest {
        const message = { delay: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageRescoreRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageRescoreRequest): ImageRescoreRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 delay */ 1:
                    message.delay = reader.int64().toBigInt();
                    break;
                case /* meta.DateRange window */ 2:
                    message.window = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.window);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageRescoreRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 delay = 1; */
        if (message.delay !== 0n)
            writer.tag(1, WireType.Varint).int64(message.delay);
        /* meta.DateRange window = 2; */
        if (message.window)
            DateRange.internalBinaryWrite(message.window, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageRescoreRequest
 */
export const ImageRescoreRequest = new ImageRescoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageRescoreResponse$Type extends MessageType<ImageRescoreResponse> {
    constructor() {
        super("brandguard.image.ImageRescoreResponse", []);
    }
    create(value?: PartialMessage<ImageRescoreResponse>): ImageRescoreResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageRescoreResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageRescoreResponse): ImageRescoreResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImageRescoreResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImageRescoreResponse
 */
export const ImageRescoreResponse = new ImageRescoreResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportFromURLRequest$Type extends MessageType<ImportFromURLRequest> {
    constructor() {
        super("brandguard.image.ImportFromURLRequest", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImportFromURLRequest>): ImportFromURLRequest {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImportFromURLRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImportFromURLRequest): ImportFromURLRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImportFromURLRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImportFromURLRequest
 */
export const ImportFromURLRequest = new ImportFromURLRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportFromURLResponse$Type extends MessageType<ImportFromURLResponse> {
    constructor() {
        super("brandguard.image.ImportFromURLResponse", []);
    }
    create(value?: PartialMessage<ImportFromURLResponse>): ImportFromURLResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImportFromURLResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImportFromURLResponse): ImportFromURLResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImportFromURLResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.ImportFromURLResponse
 */
export const ImportFromURLResponse = new ImportFromURLResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StockImportRequest$Type extends MessageType<StockImportRequest> {
    constructor() {
        super("brandguard.image.StockImportRequest", [
            { no: 1, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<StockImportRequest>): StockImportRequest {
        const message = { brand_id: "", number: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StockImportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StockImportRequest): StockImportRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string brand_id = 1 [json_name = "brand_id"];*/ 1:
                    message.brand_id = reader.string();
                    break;
                case /* int32 number */ 2:
                    message.number = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StockImportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string brand_id = 1 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.brand_id);
        /* int32 number = 2; */
        if (message.number !== 0)
            writer.tag(2, WireType.Varint).int32(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.StockImportRequest
 */
export const StockImportRequest = new StockImportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StockImportResponse$Type extends MessageType<StockImportResponse> {
    constructor() {
        super("brandguard.image.StockImportResponse", []);
    }
    create(value?: PartialMessage<StockImportResponse>): StockImportResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StockImportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StockImportResponse): StockImportResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StockImportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.StockImportResponse
 */
export const StockImportResponse = new StockImportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleImagesRequest$Type extends MessageType<GoogleImagesRequest> {
    constructor() {
        super("brandguard.image.GoogleImagesRequest", [
            { no: 1, name: "keywords", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GoogleImagesRequest>): GoogleImagesRequest {
        const message = { keywords: "", number: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoogleImagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoogleImagesRequest): GoogleImagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string keywords */ 1:
                    message.keywords = reader.string();
                    break;
                case /* int32 number */ 2:
                    message.number = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GoogleImagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string keywords = 1; */
        if (message.keywords !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.keywords);
        /* int32 number = 2; */
        if (message.number !== 0)
            writer.tag(2, WireType.Varint).int32(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.GoogleImagesRequest
 */
export const GoogleImagesRequest = new GoogleImagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleImagesResponse$Type extends MessageType<GoogleImagesResponse> {
    constructor() {
        super("brandguard.image.GoogleImagesResponse", []);
    }
    create(value?: PartialMessage<GoogleImagesResponse>): GoogleImagesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoogleImagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoogleImagesResponse): GoogleImagesResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GoogleImagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.image.GoogleImagesResponse
 */
export const GoogleImagesResponse = new GoogleImagesResponse$Type();
