import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as bgimages from "brandguard/display/brandguard/images"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import * as authz from "authz"
import classnames from "classnames"
import * as scores from "brandguard/components/layouts/scores"
import { Scores, Debug } from "brandguard/display/brandguard/images"
import * as debugx from "x/debugx"
import * as icons from "icons"
import JSZip from "jszip"
import * as media from "media"
import * as bgscores from "brandguard/display/scores"
import * as typography from "typography"

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const mtoggle = layouts.modals.useToggle()

	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.ImageSearchResponseItem | undefined)
	const [checkedItems, setCheckedItems] = useState([] as api.ImageSearchResponseItem[])
	const [filterFocused, setFilterFocused] = useState(false)

	const _searchReq = api.images.uploads.searches.zero({
		offset: 0n,
		brand_id: brand.id,
		limit: 20n,
		observation: [api.Prediction.REJECTED],
	})

	const [searchReq, setSearchReq] = useState(_searchReq)
	const [cursorKey, setCursorKey] = useState(uuid.v4())

	const [images, setImages] = useState({
		items: [],
		next: searchReq,
	} as api.ImageSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.images.approvallog.search(searchReq.brand_id, searchReq, bearertoken))
			.then(setImages)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => console.error("unable to load brandguard image approval log", cause))
			.finally(() => {
				setLoading(false)
			})
		return p.cancel
	}, [searchReq])

	useEffect(() => {
		if (!selectedItem) return
		mtoggle(
			<layouts.Flex styled flexDirection="column">
				<bgapprovals.ImagePreview
					item={selectedItem}
					flex="1"
					onClose={() => {
						mtoggle(undefined)
						setSelectedItem(undefined)
					}}
				>
					<typography.h3 fontWeight="700" fontSize="18px" lineHeight="30px" color="#4D5E80">
						Asset Scores
					</typography.h3>
					<Scores item={selectedItem} />
					<bgapprovals.status.default>
						<bgapprovals.status.ApprovalLog item={selectedItem} borderRadius="10px" />
					</bgapprovals.status.default>
					<authz.Protected enabled={debugx.alpha.enabled()}>
						<bgscores.layouts.accordion.Container title="Debug Info" textAlign="initial">
							<Debug item={selectedItem} />
						</bgscores.layouts.accordion.Container>
					</authz.Protected>
					<layouts.containers.flex className="actions" flex="1" alignItems="flex-end">
						<authz.Protected enabled={permission.brandguard_upload}>
							<bgimages.MultiActions uploadItems={[selectedItem]} />
						</authz.Protected>
					</layouts.containers.flex>
				</bgapprovals.ImagePreview>
			</layouts.Flex>,
		).then((e) => setSelectedItem(undefined))
	}, [selectedItem])

	const onImageUpdate = (item: api.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			...prevState,
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const _allselected = images.items.length > 0 && checkedItems.length === images.items.length

	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedItems([])
			return
		}
		setCheckedItems(images.items)
	}

	const fcnt = bgapprovals.CalculateFilterSCount(_searchReq, searchReq)

	return (
		<layouts.containers.flex flexDirection="column-reverse" overflow="hidden">
			<layouts.pagination.Cursor
				key={cursorKey}
				my="auto"
				justifyContent="center"
				current={searchReq.offset}
				advance={Number(images.next!.offset) === -1 ? undefined : images.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
			<layouts.loading.screen
				className="brandguard-working-area"
				loading={loading}
				flex="1"
				overflow="hidden"
				icon={<></>}
				flexDirection="row"
			>
				<layouts.containers.flex className="center-panel" flex="6" flexDirection="column">
					<layouts.containers.flex flexDirection="column" flex="1" minHeight="100px">
						<bglayouts.OverlaysContainer overflow="auto" background={layouts.theme.backgrounds.whitealpha80}>
							<layouts.containers.ResponsiveGrid gap={20} p="25px">
								{images.items.map((item) => {
									return (
										<bgapprovals.images.CardDisplay
											key={item.media?.id}
											item={item}
											active={selectedItem && item?.media?.id === selectedItem.media?.id}
											onCardClick={() => {
												setSelectedItem(item)
											}}
											onChange={onImageUpdate}
											onChecked={(checked) =>
												setCheckedItems((prevState) =>
													checked ? [...prevState, item] : prevState.filter((i) => i.media?.id !== item.media?.id),
												)
											}
											score={
												<layouts.containers.absolute>
													<scores.BGScoreImageSimple event={item.event!} />
												</layouts.containers.absolute>
											}
											panel={
												<bgapprovals.status.default>
													<bgapprovals.status.ApprovalLog item={item} borderRadius="0 0 10px 10px" />
												</bgapprovals.status.default>
											}
											find={api.images.uploads.find}
											checked={checkedItems.some((i) => i.media?.id === item.media?.id)}
										/>
									)
								})}
							</layouts.containers.ResponsiveGrid>
							<layouts.loading.pending loading={loading || images.next?.offset === searchReq.offset}>
								{images.items.length === 0 && <bgapprovals.layouts.ZeroState />}
							</layouts.loading.pending>
						</bglayouts.OverlaysContainer>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.screen>
			<layouts.containers.flex flexDirection="row" className={classnames(bgapprovals.layouts.styledactions)}>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
					<bgapprovals.layouts.FilterButton
						width="145px"
						height="40px"
						borderRadius="4px"
						onClick={() => setFilterFocused(!filterFocused)}
						className={fcnt > 0 ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">Filter{fcnt > 0 ? `/${fcnt}` : ""}</layouts.containers.box>
							<layouts.containers.box>
								{filterFocused ? <icons.bgapproval.arrowDown /> : <icons.bgapproval.arrowDown />}
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgapprovals.layouts.FilterButton>
					<layouts.containers.flex display={filterFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<bgapprovals.FilterDisplay
							onFilterClear={() => {
								setSearchReq(_searchReq)
								setCursorKey(uuid.v4())
							}}
							_searchReq={_searchReq}
							onFilterChange={(r) => {
								setSearchReq({ ...searchReq, ...r, ...{ offset: 0n } })
								setCursorKey(uuid.v4())
							}}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex className="actions" justifyContent="flex-end" flex="10">
					<bgapprovals.layouts.SelectAll
						disabled={images.items.length === 0}
						cursor="pointer"
						className="select-all-action"
						onClick={handleSelectAll}
					>
						{_allselected ? "Unselect All" : "Select All"}
					</bgapprovals.layouts.SelectAll>
					<authz.Protected
						enabled={permission.brandguard_upload && checkedItems.length > 0}
						rejected={
							<layouts.containers.flex className="download-action">
								<icons.brandguard.download />
							</layouts.containers.flex>
						}
					>
						<layouts.containers.flex
							className="download-action"
							cursor="pointer"
							onClick={() => {
								const zip = new JSZip()
								const promises: Promise<api.ImagePatchRequest | JSZip>[] = []
								checkedItems.forEach((i) => {
									promises.push(
										media.cache.current(authzaccount.current.id, i.media!.md5, bearertoken).then((url) => {
											return fetch(url)
												.then((response) => {
													return response.arrayBuffer()
												})
												.then((imageBuffer) => {
													const imageName = media.downloads.filename(i.media!.md5, i.media!.mimetype)
													return zip.file(imageName, imageBuffer)
												})
										}),
									)
								})
								Promise.all(promises).then(() => {
									zip.generateAsync({ type: "blob" }).then((content) => {
										const downloadLink = document.createElement("a")
										downloadLink.href = URL.createObjectURL(content)
										downloadLink.download = "brandguard-assets.zip"
										downloadLink.click()
									})
								})
							}}
						>
							<icons.brandguard.download />
						</layouts.containers.flex>
					</authz.Protected>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
