import React, { useCallback, useState, useEffect } from "react"
import useResizeObserver from "@react-hook/resize-observer"
import { useInView } from "react-intersection-observer"
import * as httpx from "httpx"
import { pdfjs, Document, Page, PageProps } from "react-pdf"
import type { PDFDocumentProxy } from "pdfjs-dist"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as cache from "cachinglarge"
import * as errors from "errors"
import * as pdflayouts from "./layouts"
import * as brandgptlayouts from "brandgpt/layouts"
import classnames from "classnames"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import * as icons from "icons"

pdfjs.GlobalWorkerOptions.workerSrc = httpx.urlstorage.publicUrl() + "/vendors/pdfjs/pdf.worker.min.js"
const options = {
	cMapUrl: "/cmaps/",
	standardFontDataUrl: "/standard_fonts/",
}

interface PageExtProps extends PageProps {
	pageNumber: number
	threshold?: number
	setPageVisibility: (pageNumber: number, inView: boolean) => void
}

interface pdfprops {
	fileUrl: string
	fileMd5: string
	page: PageNum
	onChangePage(page: PageNum): void
}

export interface PageNum {
	num: number
	scroll: boolean
}

const PageExt = ({ pageNumber, setPageVisibility, threshold, ...otherProps }: PageExtProps) => {
	const { ref, inView } = useInView({
		threshold: [0.9, 1],
	})

	useEffect(() => {
		setPageVisibility(pageNumber, inView)
	}, [inView])

	return <Page canvasRef={ref} pageNumber={pageNumber} {...otherProps} />
}

export default function Viewer(props: pdfprops) {
	const { fileUrl, fileMd5, page, onChangePage } = props
	const [fileBlob, setFileBlob] = useState(new Blob())
	const [totalPages, setTotalPages] = useState<number>(1)
	const [loading, setLoading] = useState(true)
	const [preloadedPages, setPreLoadedPages] = useState<number[]>([totalPages])
	const wrapperRef = React.createRef<HTMLDivElement>()
	const [width, setWidth] = useState<number>(0)
	const [reload, setReload] = useState<number>(0)
	const halfPreloadPagesCount = 10
	const fileExists = fileBlob.size > 0

	const setPageVisibility = useCallback((pageNumber: number, inView: boolean) => {
		if (inView) {
			onChangePage({ num: pageNumber, scroll: true })
		}
	}, [])

	useEffect(() => {
		if (fileMd5 === "" || fileUrl === "") return

		cache.pdfs.get(fileMd5).then((pdfData) => {
			if (pdfData) {
				console.debug("read pdf from cache")
				setFileBlob(new Blob([pdfData.data]))
				setLoading(false)
			} else {
				const retry = httpx.autoretry()
				const readpdf = retry
					.wrap(() => httpx._fetchv2(fileUrl, httpx.requests.zero()))
					.then((res) => res.blob())
					.then((b) => {
						setFileBlob(b)
						cache.pdfs
							.put({ md5: fileMd5, data: b })
							.then(() => console.debug("successfully putting pdf to cache"))
							.catch((e) => console.error(e))
							.finally(() => setLoading(false))
					})
					.catch((e) => {
						console.error(e)
						setLoading(false)
					})

				return readpdf.cancel
			}
		})
	}, [fileUrl, fileMd5])

	useEffect(() => {
		if (totalPages === 1 || (totalPages > 1 && preloadedPages.length === totalPages)) return

		const start = page.num - halfPreloadPagesCount > 0 ? page.num - halfPreloadPagesCount : 1
		const end = page.num + halfPreloadPagesCount > totalPages ? totalPages : page.num + halfPreloadPagesCount
		let pages = []
		for (let i = start; i <= end; i++) {
			pages.push(i)
		}
		if (preloadedPages.length === 1) {
			setPreLoadedPages(pages)
			return
		}
		const firstPage = preloadedPages[0]
		const lastPage = preloadedPages[preloadedPages.length - 1]
		const gap = Math.floor(halfPreloadPagesCount / 2)
		if (page.num > lastPage - gap || page.num < firstPage + gap) {
			setPreLoadedPages(pages)
		}
	}, [page.num, totalPages])

	useResizeObserver(wrapperRef, (entry) => {
		setWidth(entry.contentRect.width)
	})

	function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy): void {
		setTotalPages(numPages)
	}

	function onDocumentLoadError(): void {
		if (fileExists) {
			setReload((r) => ++r)
		}
	}

	function download(url: string) {
		const link = document.createElement("a")
		link.href = url
		link.target = "_blank"
		link.download = url
		link.click()
	}

	return (
		<layouts.containers.flex flexDirection="column" flex="1" width="100%">
			<layouts.containers.flex flexDirection="row" flex="1" maxHeight="30px" alignItems="flex-end">
				<layouts.containers.flex pl="10px" m="auto" justifyContent="left">
					<inputs.Numeric
						key={page.num}
						defaultValue={page.num}
						min={1}
						max={totalPages}
						borderRadius="0px"
						padding="0px"
						width="25px"
						className={classnames(pdflayouts.numericInput)}
						tabIndex={0}
						onBlur={(evt) => {
							onChangePage({ num: Number(evt.currentTarget.value), scroll: false })
						}}
						onKeyDown={(evt) => {
							if (evt.key !== "Enter") return
							onChangePage({ num: Number(evt.currentTarget.value), scroll: false })
						}}
					/>
					<layouts.Span position="relative" fontSize="14px" m="auto">
						/ {totalPages}
					</layouts.Span>
				</layouts.containers.flex>
				<layouts.containers.flex pr="10px" justifyContent="right" flex="1">
					<layouts.containers.flex title="Download" onClick={() => download(fileUrl)}>
						<icons.pdf.download width="20px" />
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex
				flexDirection="row"
				padding="0 10px 10px 10px"
				flex="1"
				overflow="auto"
				ref={wrapperRef}
				className={classnames(brandgptlayouts.styledscroll)}
			>
				<layouts.containers.flex
					flexDirection="column"
					alignItems="center"
					flex="1"
					className={classnames(pdflayouts.styledwrapperRef)}
				>
					<Document
						file={fileExists ? fileBlob : undefined}
						onLoadSuccess={onDocumentLoadSuccess}
						onLoadError={onDocumentLoadError}
						options={options}
						key={reload + width}
						noData={loading ? <pdflayouts.SpinnerIcon /> : <errors.Inline>Error: file is empty</errors.Inline>}
						loading={<pdflayouts.SpinnerIcon />}
					>
						<layouts.containers.flex flexDirection="column" alignItems="center" flex="1">
							{preloadedPages.map((el, index) => (
								// react-pdf in canvas uses z-index: 2; by default
								<pdflayouts.PDFPage key={el} className="page-wrapper" zIndex="0">
									<PageExt
										pageNumber={el}
										width={width}
										inputRef={
											page.num === el && !page.scroll ? (ref) => ref && ref.scrollIntoView({ block: "center" }) : null
										}
										setPageVisibility={setPageVisibility}
										loading={<></>}
									/>
								</pdflayouts.PDFPage>
							))}
						</layouts.containers.flex>
					</Document>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Viewer.defaultProps = {
	page: 1,
}
