import React, { useState, useMemo, useEffect } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as errors from "errors"
import * as brands from "brands"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as api from "brandguard/api"
import * as timex from "timex"
import * as uuid from "uuid"
import * as metasearch from "metasearch"
import * as training from "brandguard/display/training"
import * as debugx from "x/debugx"
import * as bgscores from "brandguard/display/scores"
import LogoDetection from "./settings.logo.detection"
import FontDetection from "./settings.font.detection"
import CustomRules from "./settings.custom.rules"
import BackgroundColors from "./settings.background.colors"

interface props extends layouts.containers.FlexProps {
	current: api.ImageKernelSetting
	onChange(upd: api.ImageKernelSetting): void
}

export default function Settings(props: React.PropsWithChildren<props>): JSX.Element {
	const { current, onChange, ...rest } = props
	const brand = brands.caching.useCached()
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(true)

	const admincontrols = useMemo(() => {
		function controls() {
			return (
				<training.layouts.AdminTrainingEnqueue
					enqueue={(ago: timex.DateTime) =>
						api.images.training
							.enqueue(brand.id, { ago: BigInt(ago.toMillis()) }, authzc.bearer(authzaccount))
							.then(() => undefined)
					}
				/>
			)
		}

		return controls
	}, [brand.id])

	const rescore = useMemo(() => {
		function rescore() {
			return (
				<training.layouts.RescoreEnqueue
					enqueue={(ago: timex.DateTime) => {
						return api.images.training
							.rescore(
								brand.id,
								{
									delay: BigInt(timex.duration.seconds(30).toMillis()),
									window: metasearch.dateranges.since(ago),
								},
								authzc.bearer(authzaccount),
							)
							.then(() => undefined)
					}}
				/>
			)
		}

		return rescore
	}, [brand.id])

	useEffect(() => {
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.images.kernel.config.latest(brand.id, brand.id, authzc.bearer(authzaccount)))
			.then((r) => {
				onChange(api.images.kernel.config.zero({ ...r.settings!, id: uuid.v4() }))
				setLoading(false)
				setCause(undefined)
			})
			.catch(
				httpx.errors.notFound((cause) => {
					console.debug("unable to find image kernel", cause)
					setLoading(false)
					setCause(undefined)
				}),
			)
			.catch((cause) => {
				console.warn(cause)
				setCause(
					<errors.Display cause={cause} onClick={() => setCause(undefined)}>
						failed to load settings
					</errors.Display>,
				)
				setLoading(false)
			})
		return p.cancel
	}, [])

	return (
		<layouts.Flex flexDirection="column" flex="1" color={layouts.theme.colors.grey.dark10} {...rest}>
			{debugx.alpha.enabled() && (
				<layouts.Flex flexDirection="column" mt="20px" gridGap="20px">
					{admincontrols()}
					{rescore()}
				</layouts.Flex>
			)}
			<layouts.Flex my="20px" justifyContent="end">
				<layouts.buttons.primary
					width="10ch"
					borderRadius="5px"
					onClick={() => {
						setLoading(true)
						const retry = httpx.autoretry()
						retry
							.wrap(() =>
								api.images.kernel.create_version(
									brand.id,
									brand.id,
									{ settings: current },
									authzc.bearer(authzaccount),
								),
							)
							.then((r) => {
								onChange({ ...current, id: uuid.v4() })
								setLoading(false)
								setCause(undefined)
							})
							.catch((cause) => {
								console.warn(cause)
								setCause(
									<errors.Display cause={cause} onClick={() => setCause(undefined)}>
										failed to update settings
									</errors.Display>,
								)
								setLoading(false)
							})
					}}
				>
					Save
				</layouts.buttons.primary>
			</layouts.Flex>
			<layouts.forms.Container key={current.id} loading={loading} cause={cause}>
				{debugx.alpha.enabled() && (
					<training.layouts.styledFormContainer padding="5px 10px">
						<training.layouts.styledFormLabel>Threshold</training.layouts.styledFormLabel>
						<inputs.Numeric
							defaultValue={current.threshold}
							onChange={(evt) =>
								onChange({
									...current,
									threshold: evt.target.valueAsNumber,
								})
							}
							className={training.layouts.styledInput}
							style={{
								marginLeft: "10px",
								maxWidth: "40px",
								background: layouts.theme.backgrounds.bluealpha,
								border: `1px solid ${layouts.theme.colors.grey.dark50alpha10}`,
								minHeight: "30px",
							}}
						/>
					</training.layouts.styledFormContainer>
				)}
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Image Consistency</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.brand_voice!.enabled}
						onClick={() =>
							onChange({
								...current,
								brand_voice: { ...current.brand_voice, enabled: !current.brand_voice!.enabled },
							})
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Violence</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.image_violence!.enabled}
						onClick={() =>
							onChange({
								...current,
								image_violence: { ...current.image_violence, enabled: !current.image_violence!.enabled },
							})
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<training.layouts.styledFormContainer>
					<training.layouts.styledFormLabel>Nudity Check</training.layouts.styledFormLabel>
					<inputs.toggles.Display
						ml="auto"
						mr="20px"
						checked={current.sexually_explicit!.enabled}
						onClick={() =>
							onChange({
								...current,
								sexually_explicit: { ...current.sexually_explicit!, enabled: !current.sexually_explicit!.enabled },
							})
						}
						className={training.layouts.styledToggle}
					/>
					<layouts.Flex width="14px" />
				</training.layouts.styledFormContainer>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Logo Models</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.image_logo_detection!.enabled}
							onClick={() => {
								onChange({
									...current,
									image_logo_detection: {
										...current.image_logo_detection!,
										enabled: !current.image_logo_detection!.enabled,
									},
								})
							}}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.Flex flexDirection="column">
							<layouts.Flex my="5px">
								<bgscores.layouts.accordion.subheader>
									To train your brand&apos;s scoring system on its logos, contact us at{" "}
									<a href={"mailto:developers@brandguard.ai"}>developers@brandguard.ai</a>. Please include your logo
									files in the email.
								</bgscores.layouts.accordion.subheader>
							</layouts.Flex>
							{debugx.alpha.enabled() && (
								<LogoDetection
									settings={current.image_logo_detection!}
									onChange={(upd: api.ImageKernelLogosEnabled) => {
										onChange({ ...current, image_logo_detection: upd })
									}}
								/>
							)}
						</layouts.Flex>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Background Colors</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.image_background_colors!.enabled}
							onClick={() => {
								onChange({
									...current,
									image_background_colors: {
										...current.image_background_colors!,
										enabled: !current.image_background_colors!.enabled,
									},
								})
							}}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<BackgroundColors
							settings={current.image_background_colors!}
							onChange={(upd: api.ImageKernelBackgroundColorEnabled) => {
								onChange({ ...current, image_background_colors: upd })
							}}
						/>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Font Detection</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.image_font_detection!.enabled}
							onClick={() => {
								onChange({
									...current,
									image_font_detection: {
										...current.image_font_detection!,
										enabled: !current.image_font_detection!.enabled,
									},
								})
							}}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.Flex flexDirection="column">
							<layouts.Flex my="5px">
								<bgscores.layouts.accordion.subheader>
									To train your brand&apos;s scoring system on its fonts, contact us at{" "}
									<a href={"mailto:developers@brandguard.ai"}>developers@brandguard.ai</a>. Please include your font
									files in the email.
								</bgscores.layouts.accordion.subheader>
							</layouts.Flex>
							{debugx.alpha.enabled() && (
								<FontDetection
									settings={current.image_font_detection!}
									onChange={(upd: api.ImageKernelFontEnabled) => {
										onChange({ ...current, image_font_detection: upd })
									}}
								/>
							)}
						</layouts.Flex>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
				<layouts.accordions.Container className={training.layouts.styledAccordion}>
					<layouts.accordions.Description>
						<training.layouts.styledFormLabel>Look & Feel</training.layouts.styledFormLabel>
						<inputs.toggles.Display
							ml="auto"
							mr="20px"
							checked={current.image_obscure_rules!.enabled}
							onClick={() => {
								onChange({
									...current,
									image_obscure_rules: {
										...current.image_obscure_rules!,
										enabled: !current.image_obscure_rules!.enabled,
									},
								})
							}}
							className={training.layouts.styledToggle}
						/>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<CustomRules
							settings={current.image_obscure_rules!}
							onChange={(upd: api.ImageKernelObscureRulesEnabled) => {
								onChange({ ...current, image_obscure_rules: upd })
							}}
						/>
					</layouts.accordions.Content>
				</layouts.accordions.Container>
			</layouts.forms.Container>
		</layouts.Flex>
	)
}
